<div class="a4" id="content">
  <div style="padding-top: 20px; padding-bottom: 3px;" align="center" class="heading"><strong>DELIVERY NOTE</strong></div>
  <table width="100%" style="height: 150px;" border="0" class="maintd" align="center" cellspacing="0" cellpadding="0">
    <tr>
      <td>
        <table width="100%" border="0" cellspacing="0" cellpadding="0">
          <tr>
            <td width="50%" valign="top" style="border-right: 1px solid; padding: 5px; border-bottom: 1px solid">
              <div style="min-height: 30px;">
                <strong>Date :</strong> <span>
                                    {{deliveryDate}}
                                </span>
              </div>
            </td>
            <td width="50%" valign="top" style="padding: 5px; border-bottom: 1px solid">
              <strong>DN# :</strong> <span>
                                {{data[0].deliveryId}}
                            </span>
            </td>
          </tr>
          <tr>
            <td width="50%" valign="top" style="min-height: 200px; padding: 5px; border-right: 1px solid; border-bottom: 1px solid">
              <!--style="min-height: 80px;"-->
              <div>
                <strong class="entityname">Entity Name :</strong>
                <br />
                <span>
                                    {{data[0].companyName}}
                                </span>
              </div>
            </td>
            <td width="50%" valign="top" style="min-height: 200px; padding: 5px; border-bottom: 1px solid">
              <strong>Department Name : </strong>
              <br />
              <span>
                                {{data[0].deptName}}
                             </span>
            </td>
          </tr>
          <tr>
            <td colspan="2" width="50%" valign="top" style="padding: 5px; border-right: 1px solid">
              <div style="min-height: 25px;">
                <strong>MRN# :</strong>
                <!--<br /> -->
                <span>
                                    {{data[0].mrnId}}
                                </span>
              </div>
            </td>
          </tr>
        </table>
      </td>
    </tr>
    <tr>
      <td>
        <table id="tb2" width="100%" border="1" class="bfull" cellspacing="0" cellpadding="5">
          <tr class="font16">
            <th style="width: auto; max-width: 5%" align="center" valign="middle">S.No.</th>
            <th style="width: auto; max-width: 10%" align="center" valign="middle">Ind. #</th>
            <th style="width: auto; max-width: 35%" align="center" valign="middle">Material</th>
            <th style="width: auto; max-width: 9%" align="center" valign="middle">PO# / Cash</th>
            <th style="width: auto; max-width: 6%" align="center" valign="middle">Issued Qty</th>
            <th style="width: auto; max-width: 6%" align="center" valign="middle">UOM</th>
            <th style="width: auto; max-width: 6%" align="center" valign="middle">Bill No</th>
            <th style="width: auto; max-width: 14%" align="center" valign="middle">Amt</th>
          </tr>
          <tr *ngFor="let i of arrDnDetail" class="font16">
            <td class="tabletwo"><span></span></td>
            <td><span>{{i.indentNo}}</span></td>
            <td style="width:auto;word-break: break-all;"><span><b>{{i.itemName}}</b><br />{{i.itemSpecDesc}}</span></td>
            <td><span>{{i.poId != '' ? i.poId : 'Cash'}}</span></td>
            <td><span>{{i.deliverQty}}</span></td>
            <td><span>{{i.deliverQtyUom}}</span></td>
            <td style="min-width: 70px; max-width: 120px; word-break: break-all;"><span>{{i.supplierBillNo}}</span></td>
            <td align="center"><span>{{i.totalRate}}</span></td>
          </tr>
          <tr class="font16">
            <td colspan="3" style="border: none;">&nbsp;</td>
            <td align="right" colspan="3">Total</td>
            <td align="right" colspan="2">&#8377; <strong>
              {{data[0].grandTotal}}
            </strong></td>
          </tr>
        </table>
      </td>
    </tr>
    <tr>
      <td>
        <table width="100%" border="0" cellspacing="0" cellpadding="5">
          <tr>
            <td width="60%">Amount Chargeable (in words)</td>
            <td width="40%" align="right">E. &amp; O. E</td>
          </tr>
          <tr>
            <td colspan="2" align="left"><b>
              <!--Rupees-->
              {{data[0].rupeersInWords}}
            </b>
            </td>
          </tr>
          <tr>
            <!--height: 140px;-->
            <td colspan="2" style="height: 20px;">&nbsp;</td>
          </tr>
          <tr>
            <!--colspan="2"-->
            <td rowspan="3" valign="top"><b>Remarks:</b><br />
              <div class="remarks">
                {{data[0].deliveryComment}}
              </div>
            </td>
          </tr>
          <tr>
            <td align="left" valign="top" class="btop bleft" style="height: 50px; padding: 1px; padding-top: 5px;">
              <b>Received By</b><br />
              <span style="padding-left:15px;padding-top:25px;">
                               {{data[0].deliveryTakenBy}}
                            </span>
            </td>
          </tr>
          <tr>
            <!--<td>&nbsp;</td>-->
            <td align="right" class="bleft">&nbsp;</td>
          </tr>
        </table>

      </td>
    </tr>
  </table>
  <!--  <div id="content">
   <div id="pageFooter">Page </div>
  </div>-->
</div>