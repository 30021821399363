import { Component, OnInit } from '@angular/core';
import {
  MatSnackBar,
  MatSnackBarConfig
} from '@angular/material';

@Component({
  selector: 'app-snack-bar',
  templateUrl: './snack-bar.component.html',
  styleUrls: ['./snack-bar.component.css']
})
export class SnackBarComponent implements OnInit {

  constructor(public snackBar: MatSnackBar) { }

  ngOnInit() {
  }

  openSnackSuccess(message: string) {
    let config = new MatSnackBarConfig;
    config.duration = 4000;
    config.verticalPosition = 'bottom';
    config.panelClass = 'success';
    this.snackBar.open(message, '', config);
  }

  openSnackSuccessTop(message: string) {
    let config = new MatSnackBarConfig;
    config.duration = 4000;
    config.verticalPosition = 'top';
    config.panelClass = 'success';
    this.snackBar.open(message, '', config);
  }

  openSnackFailureTop(message: string) {
    let config = new MatSnackBarConfig;
    config.duration = 8000;
    config.verticalPosition = 'top';
    config.panelClass = 'failure';
    this.snackBar.open(message, 'close', config);
  }

  openSnackFailure(message: string) {
    let config = new MatSnackBarConfig;
    config.duration = 5000;
    config.verticalPosition = 'top';
    config.panelClass = 'failure';
    this.snackBar.open(message, 'Close', config);
  }

  static dateConvertor(date: Date): string {
    let outputDate: string = '';

    let mm: string = (date.getMonth() + 1).toString();
    let dd: string = (date.getDate()).toString();
    let yy: string = (date.getFullYear()).toString();

    outputDate = `${yy}-${mm}-${dd}`;
    return outputDate;
  }

}
export class Convert {
  static toString(input: any): string {
    if (input == null)
      return ""
    else
      return input.toString()
  }
}
