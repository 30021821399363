import { Injectable } from '@angular/core';
import {
    HttpClient,
    HttpParams,
    HttpHeaders
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class RootAPIService {
    Root_URL = environment.dais;

    constructor(private _http: HttpClient) {
        this._http.get('../../assets/configs/url-configs.json').subscribe(response => {
            let res: any = response;
            
            if (window.location.href.indexOf(res.windowLocation.local) > -1) {
                this.Root_URL = environment.dais;
              } else if (window.location.href.indexOf(res.windowLocation.qa) > -1) {
                this.Root_URL = environment.dais;
              } else if (window.location.href.indexOf(res.windowLocation.prod) > -1) {
                this.Root_URL = environment.dais;
              }
        });
    }

    public GetData<TOutput>(apiName: string, httpParams: HttpParams): Observable<TOutput> {
        const httpOptions = {
            headers: new HttpHeaders({
                
            }),
            params: httpParams
        };
        return this._http.get<TOutput>(`${this.Root_URL}${apiName}`, httpOptions);
    }

    public PostData<TResponse, TInput>(apiName: string, input: TInput): Observable<TResponse> {
        return this._http.post<TResponse>(`${this.Root_URL}${apiName}`, input);
    }

    public PutData<TResponse, TInput>(apiName: string, input: TInput): Observable<TResponse> {
        return this._http.put<TResponse>(`${this.Root_URL}${apiName}`, input);
    }
}
