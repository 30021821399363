/*
* This configuration file of navigation is to have key and value of the object that are used in the navigation component. 
*/
// export const navigation : NavigationLink[] = [
//     {
//         name: 'ID',
//         desc: 'Indent Draft',
//         routerLink: 'indent/create-indent-draft',
//         icon: 'fab fa-firstdraft'
//     },
//     {
//         name: 'IDL',
//         desc: 'Indent Draft List',
//         routerLink: 'indent/indent-draft-list',
//         icon: 'fab fa-firstdraft'
//     },
//     {
//         name: 'DI',
//         desc: 'Department Indent Approval Authorities',
//         routerLink: 'department-indent/approval-authorities',
//         icon: 'fas fa-clipboard-check'
//     },
//     {
//         name: 'DI (R)',
//         desc: 'Department Indent Requestor',
//         routerLink: 'department-indent/requestor',
//         icon: 'fas fa-clipboard-check'
//     },
//     {
//         name: 'DLM',
//         desc: 'Department Ledger Mapping',
//         routerLink: 'mappings/department-ledger',
//         icon: 'fas fa-sitemap'
//     },
//     {
//         name: 'AC',
//         desc: 'Approval Configuration',
//         routerLink: 'approvals/configuration',
//         icon: 'fas fa-check-double'
//     },
//     {
//         name: 'LHA',
//         desc: 'Legder Head Approval',
//         routerLink: 'approvals/ledger-head',
//         icon: 'fas fa-check-double'
//     },
//     {
//         name: 'AUTH',
//         desc: 'User Access',
//         routerLink: 'auth/user-access',
//         icon: 'fas fa-fingerprint'
//     }, 
//     {
//         name: 'LHM',
//         desc: 'Ledger Head Masters',
//         routerLink: 'masters/ledger-head',
//         icon: 'fas fa-university'
//     },
//     {
//         name: 'EM',
//         desc: 'Entity Masters',
//         routerLink: 'masters/entity',
//         icon: 'fas fa-university'
//     },
//     {
//         name: 'BM',
//         desc: 'Budget Masters',
//         routerLink: 'masters/budget',
//         icon: 'fas fa-university'
//     }
// ];

// export interface NavigationLink {
//     name: string;  //Name of the menu
//     desc: string; //about the menu 
//     routerLink: string; //Path of the menu
//     icon: string; //icon of the menu
// }


export const navigation: NavigationLink[] = [
    {
        name: 'DRAFT',
        desc: 'Draft Module',
        icon: 'fab fa-firstdraft',
        children: [
            {
                name: 'ID',
                desc: 'Indent Draft',
                routerLink: 'indent/create-indent-draft',
                icon: 'fab fa-firstdraft'
            },
            {
                name: 'IDL',
                desc: 'Indent Draft List',
                routerLink: 'indent/indent-draft-list',
                icon: 'fab fa-firstdraft'
            }
        ]
    },
    {
        name: 'DI',
        desc: 'Department Indent Module',
        icon: 'fas fa-clipboard-check',
        children: [
            {
                name: 'DI (A)',
                desc: 'Department Indent Approval Authorities',
                routerLink: 'department-indent/approval-authorities',
                icon: 'fas fa-clipboard-check'
            },
            {
                name: 'DI (R)',
                desc: 'Department Indent Requestor',
                routerLink: 'department-indent/requestor',
                icon: 'fas fa-clipboard-check'
            },
            {
                name: 'N (R)',
                desc: 'Requester Notification',
                routerLink: 'department-indent/requester-notification',
                icon: 'fas fa-clipboard-check'
            }
        ]
    },
    {
        name: 'APPROVAL',
        desc: 'Approval Module',
        icon: 'fas fa-check-double',
        children: [
            {
                name: 'AC',
                desc: 'Approval Configuration',
                routerLink: 'approvals/configuration',
                icon: 'fas fa-check-double'
            },
            {
                name: 'LHA',
                desc: 'Legder Head Approval',
                routerLink: 'approvals/ledger-head',
                icon: 'fas fa-check-double'
            },
            {
                            name: 'LIA',
                            desc: 'Legder Head Indent Approval',
                            routerLink: 'approvals/ledger-head-indent',
                            icon: 'fas fa-check-double'
            },
        ]
    },
    {
        name: 'MAP',
        desc: 'Mapping Module',
        icon: 'fas fa-sitemap',
        children: [
            {
                name: 'DLM',
                desc: 'Department Ledger Mapping',
                routerLink: 'mappings/department-ledger',
                icon: 'fas fa-sitemap'
            }
        ]
    },
    {
        name: 'AUTH',
        desc: 'Authorisation Module',
        icon: 'fas fa-fingerprint',
        children: [
            {
                name: 'AUTH',
                desc: 'User Access',
                routerLink: 'auth/user-access',
                icon: 'fas fa-fingerprint'
            }
        ]
    },
    {
        name: 'MASTERS',
        desc: 'Masters Module',
        icon: 'fas fa-university',
        children: [
            {
                name: 'LHM',
                desc: 'Ledger Head Masters',
                routerLink: 'masters/ledger-head',
                icon: 'fas fa-university'
            },
            {
                name: 'EM',
                desc: 'Entity Masters',
                routerLink: 'masters/entity',
                icon: 'fas fa-university'
            },
            {
                name: 'BM',
                desc: 'Budget Masters',
                routerLink: 'masters/budget',
                icon: 'fas fa-university'
            }
        ]
    } , {
             name: 'Superadmin',
             desc: 'Superadmin Module',
             icon: 'fas fa-sitemap',
             children: [
                 {
                     name: 'SAS',
                     desc: 'Superadmin Support Menus',
                     routerLink: 'superadmin/support',
                     icon: 'fas fa-user-cog'
                 }
             ]
         }
];

export interface UserNavigation {
    userRoles: NavigationLink[];
}

export interface NavigationLink {
    name: string;  //Name of the menu
    desc: string; //about the menu 
    icon: string; //icon of the menu
    children: NavigationLinkChild[];
}

export interface NavigationLinkChild {
    name: string;
    desc: string;
    routerLink: string;
    icon: string;
}
