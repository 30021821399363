export class DepartmentIndentApprovalAuthoritiesList {
    rowKey: string;
    detailId: number;
    indentHeaderId: number;
    deptId: number;
    deptName: string;
    entityId: number;
    entityName: string;
    approvalConfigRowKey: string;
    approvalPerson: string;
    typeLevel: number;
    approvedBy: string;
    approvedAt: string;
    itemId: number;
    itemName: string;
    itemCategoryId: number;
    specId: number;
    specDesc: string;
    itemUom: string;
    purposeId: number;
    purpose: string;
    budgetCode: string;
    ledgerId: number;
    ledgerHead: string;
    lateSubmissionReason: string;
    headerRemarks: string;
    remarks: string;
    refLink: string;
    approximatePrice: number;
    attachmentIds: string[];
    itemQty: number;
    budgetId: number;
    itemStatus: string;
    status: string;
    detailCreateDate: string;
    headerCreateDate: string;
    detailCreateDateFormat: string;
    itemWfStatus: string;
    deApprovalStatus: string;

    totalPrice?: number;
    itemStatusDetail: string;
    isItemEditable: boolean;
    isEnableDateException: boolean;
    modifiedBy: string;
}

/* Approve - Reject */
export class ApprovalSave {
    detailId: number;
	itemId: number;
	itemName: string;
	specId: number;
	specDesc: string;
	itemUom: string;
	itemQty: number;
	remarks: string;
	status: string;
	modifiedBy: string;
	process: string;
	clientId: string;
    action: string;
	approvalConfigRowKey: string;
}

export class RequesterSaveOrDateException {
    indentDetailIds: string;
    remarks: string;
    status: string;
    modifiedBy: string;
    process: string;
    clientId: string;
    action: string;
}   

export class RequesterNotification {
    id: number;
    indentDetailId: number;
    indentHeaderId: number;
    deptId: number;
    oldValue: string;
    newValue: string;
    notificationType: string;
    createdBy: string;
    createdAt: number;
    requesterRemarks: string;
    notificationClient: string;
    notificationStatus: string;
    responserClient: string;
    responsedBy: string;
    responsedAt: number;
    responserRemarks: string;
    responseStatus: string;
    statusName: string;
}

export class RequesterAction {
    notificationId: number;
    status: string;
    clientId: string;
    modifiedBy: string;
    remarks: string;
    process: string;
    action: string;
    detailId: number;
}

export class SaveForDateException {
    indentDetailId: number;
    remarks: string;
    status: string;
    modifiedBy: string;
    process: string;
    clientId: string;
    action: string;
}


export class ApprovalByEmail {
    indentNumbers: string;
    isDeApproval: string;
    loggedBy: string;
}

export class ReminderByEmail {
    deptId: string;
    loggedBy: string;
}

