import { Component, ElementRef, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { SnackBarComponent , Convert } from 'src/app/shared/snack-bar/snack-bar.component';
import {  Optional, Inject } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef, MatSelectChange} from '@angular/material';

import {MRNHeaderReportDetails, MRNDetailReportDetails, MRNReportParams } from 'src/app/businessModules/reports/indent-status-report/indent-status-report-model/indent-status-report.model';
import { Subscription } from 'rxjs';

import { CommonService } from 'src/app/shared/commonService/common.service';
@Component({
  selector: 'app-mrn-print',
  templateUrl: './mrn-print.component.html',
  styleUrls: ['./mrn-print.component.css']
})

export class MrnPrintComponent   implements OnInit {

  //arrPoPrint: PrintPurchaseOrder[] = [];
  data: MRNHeaderReportDetails[] = [];
  listSubscription : Subscription[] = [];
  userName: string = "";
  arrMrnItem: MRNDetailReportDetails[] = [];
  deliveryDate: string = "";
  @ViewChild('content') po : ElementRef;
   constructor(private route: ActivatedRoute,
    public dialogRef: MatDialogRef<MRNReportParams>,
    private router: Router,
    @Optional() @Inject(MAT_DIALOG_DATA) public ddata: any,

    private snackBarComponent: SnackBarComponent,


    private _commonService: CommonService) {

     }

  ngOnInit() {
    this.getPoPrint();
    this.userName = 'xxx';

  }


  /* retrieving the data for PO print */
  getPoPrint(): void {

      let mrnParameter: MRNReportParams = new MRNReportParams();
           mrnParameter.deptId = '';
           mrnParameter.fromDate = '';
           mrnParameter.toDate = '';
           mrnParameter.supplierId = '';
           mrnParameter.mrnId =this.ddata;

           let SubscribeMrnPrint: Subscription = this._commonService.getMRNReport(mrnParameter)
               .subscribe(response => {

                   console.log('response from MRN API : ', response);
                   this.data = response;

                   this.data.map(item => {
                       this.arrMrnItem = item.mrnItemReport;
                       this.arrMrnItem.map(item => {
                           item.balanceQty = item.mrnDetailQty - item.mrnReceivedQty;
                       });
                   });

                   setTimeout(() => {
                       let printContents, popupWin;
                       printContents = document.getElementById('print-section').innerHTML;
                       console.log('inner HTML : ', printContents);
                       popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
                       popupWin.document.open();
                       popupWin.document.write(`
             <html>
             <head>
               <title>Print tab</title>
               <style>
               .remarks {
                 word-wrap: break-word;
                 display: block;
                 max-width: 680px;
             }

             .address {
                 word-wrap: break-word;
                 /*display: block;*/
                 max-width: 240px;
             }

             div[size="A4"] {
                 background: white;
                 width: 21cm;
                 height: 30.4cm;
                 display: block;
                 margin: 0 auto;
                 position: relative;
                 margin-bottom: 0.5cm;
                 box-shadow: 0 0 0.5cm rgba(0,0,0,0.5);
                 font-family: Arial, Helvetica, sans-serif;
             }

             .bleft {
                 border-left: solid 1px #666666;
             }

             .bright {
                 border-right: solid 1px #666666;
             }

             .btop {
                 border-top: solid 1px #666666;
             }

             .bbottom {
                 border-bottom: solid 1px #666666;
             }

             .maintd {
                 width: 700px;
                 border: solid 1px #666666;
             }

             .bfull {
                 border: solid 1px #666666;
                 border-collapse: collapse;
             }

             #footer td {
                 border: 1px solid #666666;
             }

             #mytable {
                 counter-reset: serial-number;  /* Set the serial number counter to 0 */
             }
             #mytable .tabletwo:before {
                 counter-increment: serial-number;  /* Increment the serial number counter */
                 content: counter(serial-number);  /* Display the counter */
             }

             @media print {
                 body, div[size="A4"] {
                     margin: 0;
                     box-shadow: 0;
                 }
             }
               </style>
             </head>
             <body onload="window.print();window.close()">${printContents}</body>
           </html>`);
                       popupWin.document.close();
                   }, 3000);

               }, (error: HttpErrorResponse) => {
                  // this.exceptionHandlingService.throwException(error);
               });
           this.listSubscription.push(SubscribeMrnPrint);
  }

  ngOnDestroy(): void {
    this.listSubscription.map(subscribe => {
      subscribe.unsubscribe();
    });

  }
}
