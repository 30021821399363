import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatCheckboxChange } from '@angular/material';
import { SnackBarComponent } from 'src/app/shared/snack-bar/snack-bar.component';
import { FormGroup } from '@angular/forms';
import { SelectionModel } from '@angular/cdk/collections';

@Component({
  selector: 'app-add-mail-id',
  templateUrl: './add-mail-id.component.html',
  styleUrls: ['./add-mail-id.component.css']
})

export class AddMailIdComponent implements OnInit, OnDestroy {
  loadingSpinner: boolean = false;

  constructor(public dialogRef: MatDialogRef<AddMailIdComponent>,
    private snackbarComponent: SnackBarComponent,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  listEmail: Array<String> = [];
  //selectedEmail: Array<String> = [];
  todo;
  passData: boolean = true;
  index: number;
  //selection = new SelectionModel<String[]>(true, []);
  //check: boolean = false;

  ngOnInit() {
    console.log('this is the data acquired from department', this.data);
    console.log(this.data['processType']);
    console.log(this.data.processType);
       console.log(this.data['primaryEmailId']);
          console.log(this.data.data['ccMailIds']);

    if (this.data['processType'] === "MA" || this.data['processType'] === "FC") {
      this.editCcMailId();
    }
    else {
      //this.listEmail;
      if (this.data.index < 2) {
        this.index = this.data.index;
      }
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  /* onchnaging the mat checkbox 
  onChange(event: MatCheckboxChange, value){
    console.log('MatCheckBox', event);
    if(event.checked == true){
      this.selectedEmail.push(value);
      console.log('Selected Email List', this.selectedEmail);
    } else {
      this.selectedEmail.map(value, i => {
        this.selectedEmail.splice(i,1);
      });
      console.log('Selected Email List after splice', this.selectedEmail);
    }
  }*/

  editCcMailId() {

      console.log(this.data.data.ccMailIds);
    if(this.data['processType'] === "MA") {
      if(this.data['ccMailIds']!=undefined){
          console.info(this.data['ccMailIds']);
          if(this.data['ccMailIds'].length>0){
                this.listEmail=this.data['ccMailIds'];
          }
      }
      if(this.data.data['ccMailIds']!=undefined){
                console.info(this.data.data['ccMailIds']);
                if(this.data.data['ccMailIds'].length>0){
                      this.listEmail=this.data.data['ccMailIds'];
                }
      }
    } else if(this.data['processType'] === "FC") {
         if(this.data['ccMailIds']!=undefined){
             if(this.data['ccMailIds'].length>0){
                 console.info(this.data['ccMailIds']);
                 this.listEmail=this.data['ccMailIds'];
             }
         }
         if(this.data.data['ccMailIds']!=undefined){
                         console.info(this.data.data['ccMailIds']);
                         if(this.data.data['ccMailIds'].length>0){
                               this.listEmail=this.data.data['ccMailIds'];
                         }
         }
    } else {
      this.index = this.data.index;
      if (this.data.index == 0) {
        //this.listEmail = this.data[1].ccMailIds;
      } else {
       // this.listEmail = this.data[2].ccMailIds;
      }
    }
    console.info( this.listEmail);
  }

  /* add item */
  addTodo(value) {
    this.loadingSpinner = true;
    console.log(value);
    if (value !== "") {
      this.listEmail.push(value);
      this.loadingSpinner = false;
    } else {
      this.snackbarComponent.openSnackFailure("Valid email ID is required");
      this.loadingSpinner = false;
    }
  }

  /* delete item*/
  deleteItem(todo) {
    for (let i = 0; i <= this.listEmail.length; i++) {
      if (todo == this.listEmail[i]) {
        this.listEmail.splice(i, 1)
      }
    }
  }

  public consolidateMail(): void {
    if (this.listEmail.length > 0) {
      console.log('string of emailIds', this.listEmail);
      this.dialogRef.close(this.passData);
    } else {
      this.snackbarComponent.openSnackFailure("CC Mail list is empty");
      this.listEmail = [];
      this.dialogRef.close(this.passData);
    }
  }

  ngOnDestroy() {
  }

}
