import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MaterialModule } from './shared/angularMaterial/material-module';
import { CoreModule } from './core/core.module';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'; 
import { DmsInterceptor } from './core/interceptors/dms.interceptor';
import { RootAPIService } from './shared/rootService/rootApiService';
import { RootPurchaseApiService } from './shared/rootService/rootPurchaseApiService';

import { MatDialogModule, MatTooltipModule, MatTabGroup, MatTabsModule } from '@angular/material';
import { SnackBarComponent } from './shared/snack-bar/snack-bar.component';
import { ConfirmationDialogComponent } from './shared/confirmation-dialog/confirmation-dialog.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BottomSheetComponent } from './shared/bottom-sheet/bottom-sheet.component';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';

import { KeycloakService, KeycloakAngularModule } from 'keycloak-angular';
import { initializer } from '../app/app-init';
import { ExpandMenu } from './shared/directives/expandMenu';
import { WelcomeHomeComponent } from './shared/welcome-home/welcome-home.component';
import { NotificationComponent } from './shared/notification/notification.component';
import { RemarksComponent } from './shared/remarks/remarks.component';
import { SwapComponent } from './shared/swap/swap.component';
import { IndentFlowReportService } from './businessModules/reports/indent-flow/indent-flow-service/indent-flow-service.service';
import { RemarksDisplayComponent } from './shared/remarks/remarks-display/remarks-display.component';
import { RemarksHistoryDisplayComponent } from './shared/remarks/remarks-history-display/remarks-history-display.component';
import { ExportToExcelService } from './shared/export-to-excel/export-to-excel.service';
import { RemarksHistoryVDisplayComponent } from './shared/remarks/remarks-history-vdisplay/remarks-history-vdisplay.component';
import {ShowRequesterValueComponent} from './shared/show-requester-value/show-requester-value.component';
import { PoPrintComponent } from 'src/app/businessModules/reports/indent-status-report/po-print/po-print.component';
import { DnPrintComponent } from 'src/app/businessModules/reports/indent-status-report/dn-print/dn-print.component';
import { RemarksHistorySadminComponent } from './shared/remarks/remarks-history-sadmin/remarks-history-sadmin.component';
import { MrnPrintComponent } from 'src/app/businessModules/reports/indent-status-report/mrn-print/mrn-print.component';

import { AddMailIdComponent } from 'src/app/businessModules/masters/department/add-mail-id/add-mail-id.component';

@NgModule({
  declarations: [
    AppComponent,
    SnackBarComponent,
    ConfirmationDialogComponent,
    BottomSheetComponent,
    ExpandMenu,
    WelcomeHomeComponent,
    NotificationComponent,
    RemarksComponent,
    SwapComponent,
    RemarksDisplayComponent,
    RemarksHistoryDisplayComponent,
    RemarksHistoryVDisplayComponent,
    RemarksHistorySadminComponent,
    ShowRequesterValueComponent,
    PoPrintComponent,
    DnPrintComponent,
    MrnPrintComponent,
    AddMailIdComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MaterialModule,
    AppRoutingModule,
    HttpClientModule,
    CoreModule,
    MatDialogModule,
    FormsModule,
    ReactiveFormsModule,
    MatBottomSheetModule,
    KeycloakAngularModule,
    MatTooltipModule,
    MatTabsModule
  ],
  providers: [ 
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DmsInterceptor,
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializer,
      multi: true,
      deps: [KeycloakService]
    },
    SnackBarComponent,
    RootAPIService,
    RootPurchaseApiService,
    IndentFlowReportService,
    ExportToExcelService
  ],
  entryComponents: [
    ConfirmationDialogComponent,
    BottomSheetComponent,
    RemarksComponent,
    SwapComponent,
    RemarksDisplayComponent,
    RemarksHistoryDisplayComponent,
    RemarksHistoryVDisplayComponent,
    RemarksHistorySadminComponent,
    ShowRequesterValueComponent,
    PoPrintComponent,
    DnPrintComponent,
    MrnPrintComponent,
    AddMailIdComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

