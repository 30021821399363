import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RootAPIService } from 'src/app/shared/rootService/rootApiService';
import { IndentFlowDetails, IndentFlowParams, IndentCurrentStatusDetails, ItemCorrectionDetails, PurchaseStatusDetails } from '../indent-flow-model/indent-flow-model';
import { HttpParams } from '@angular/common/http';

@Injectable()
export class IndentFlowReportService {

  constructor(private _rootApiService: RootAPIService) { }

  getIndentFlowReportData(getReportDataParams: IndentFlowParams): Observable<IndentFlowDetails[]> {
    return this._rootApiService.PostData<IndentFlowDetails[], IndentFlowParams>('reports/indentFlow', getReportDataParams);
  }

  getIndentCurrentStatusData(indentDetailId: number): Observable<IndentCurrentStatusDetails[]> {
    const httpParams = new HttpParams()
    return this._rootApiService.GetData<IndentCurrentStatusDetails[]>(`indentInfo/detailApprovals/${indentDetailId}`, httpParams);
  }

  getItemCorrectionData(indentDetailId: number): Observable<ItemCorrectionDetails[]> {
    const httpParams = new HttpParams()
    return this._rootApiService.GetData<ItemCorrectionDetails[]>(`indentInfo/itemCorrectionDetail/${indentDetailId}`, httpParams);
  }

  getPurchaseStatusData(indentDetailId: number): Observable<PurchaseStatusDetails[]> {
    const httpParams = new HttpParams()
    return this._rootApiService.GetData<PurchaseStatusDetails[]>(`indentInfo/itemStatusDetail/${indentDetailId}`, httpParams);
  }
}
