import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { CommonDataService } from 'src/app/shared/commonService/common-data.service';
import { Observable, timer } from 'rxjs';
import { interval, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { CommonService } from 'src/app/shared/commonService/common.service';
import { SnackBarComponent } from 'src/app/shared/snack-bar/snack-bar.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  @Output() public hover = new EventEmitter();
  @Output() public notifyToggle = new EventEmitter();

  constructor(private _keycloakService: KeycloakService,
    private _snackcBarComponent: SnackBarComponent,
    private _commonService: CommonService,
    private _commonDataService: CommonDataService) { }
  
  userName: string;
  userEmail: string;
  subscription: Subscription;
  itemNotificationCount: number;
  isNotifyCountZero: boolean = false;
  isUserDetailsLoadedFromKeycloak: boolean;

  ngOnInit() {
    this.loadUserProfile();
  }

  private getNotificationCount(){
    if(this.isUserDetailsLoadedFromKeycloak){
      this.subscription = timer(0, 50000).pipe(
        switchMap(() => this._commonService.getNotificationCount('DAIS', this.userEmail, '1'))
      ).subscribe(result => {
        this.itemNotificationCount = result;
        this.isNotifyCountZero = this.itemNotificationCount != 0 ? false : true;
        this._commonDataService.fetchitemNotifyCount(this.itemNotificationCount);
      });
      this._commonDataService.gotUserDetailsFromKeycloak(this.isUserDetailsLoadedFromKeycloak);
    } else {
      console.log('[HEADER] - User details are not available');
    }
  }

  private loadUserProfile() {
    this._keycloakService.loadUserProfile().then((value) => {
      this.userName = value.username;
      this.userEmail = value.email;
      this._commonDataService.changeUserName(this.userName);
      this._commonDataService.changeUserEmail(this.userEmail);
      this.isUserDetailsLoadedFromKeycloak = this.userEmail ? true : false;
      this.getNotificationCount(); 
    }); 
  }

  /**
   * toggle for navigation
   * @param e 
   */
  fireToggle(e) {
    this.hover.emit(e);
  }

  /**
   * toggle for notification
   * @param e 
   */
  notificationToggle(e) {
    console.log('notification toggle');
    this._commonDataService.isuserHasRequesterRoleObs.subscribe(isUserRequester => {
      if(isUserRequester){
        this.notifyToggle.emit(e);
      }else {
        this._snackcBarComponent.openSnackFailure('Only Requester Role users can access notifications');
      }
    });
  }

  /**
   * Logout of the application
   */
  logout() {
    this._keycloakService.logout();
  }

}
