import { Component, OnInit } from '@angular/core';
import { CommonDataService } from '../commonService/common-data.service';

@Component({
  selector: 'app-welcome-home',
  templateUrl: './welcome-home.component.html',
  styleUrls: ['./welcome-home.component.css']
})
export class WelcomeHomeComponent implements OnInit {

  constructor(private _commonDataService: CommonDataService) { }
  userEmail: string;
  userName: string;

  ngOnInit() {
    this._commonDataService.keycloakUserEmailObs.subscribe(result => {
      this.userEmail = result;
    });
    this._commonDataService.keycloakUserNameObs.subscribe(result => {
      this.userName = result;
    });
  }

}
