import { Component, OnInit } from '@angular/core';
import { CommonDataService } from '../commonService/common-data.service';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css']
})
export class NotificationComponent implements OnInit {

  constructor(private _commonDataService: CommonDataService) { }

  itemNotificationCount: number;  
  maNotificationCount: number = 0;
  fcNotificationCount: number = 0;

  ngOnInit() {
    this._commonDataService.itemNotificationCountObs.subscribe(result => {
      this.itemNotificationCount = +result;
    });
  }

}
