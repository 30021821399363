<div class="dim-container" [class.dim-is-mobile]="mobileQuery.matches">
  <app-header (hover)="sideNav()" (notifyToggle)="sideNotify()"></app-header>

  <mat-sidenav-container class="dim-sidenav-container" [style.marginTop.px]="mobileQuery.matches ? 56 : 0">
    <mat-sidenav #snav [mode]="mobileQuery.matches ? 'side' : 'over'" [fixedInViewport]="mobileQuery.matches"
      fixedTopGap="56">
      <mat-nav-list>
        <app-navigation></app-navigation>
      </mat-nav-list>
    </mat-sidenav>

    <mat-sidenav #notify mode="over" position="end" class="sideNotify">
      <app-notification></app-notification>
    </mat-sidenav>

    <mat-sidenav-content>
      <div class="content-wrapper">
          <router-outlet></router-outlet>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>