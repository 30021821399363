<section *ngIf="!isItForConfirmation; else forConfirm">
  <div class="container">
    <div class="row">
      <div class="col-md-12 textCenter">
        <mat-icon class="editColor">folder</mat-icon>
        <p class="draftName">{{draftName}}</p>
        <mat-form-field>
          <input matInput #message maxlength="15" [(ngModel)]="draftName" placeholder="Enter draft name">
          <mat-hint align="end">{{message.value.length}} / 15</mat-hint>
          <mat-hint *ngIf="message.value === ''">Draft Name is mandatory</mat-hint>
        </mat-form-field>
        <button mat-raised-button (click)="close()" [disabled]="message.value === ''">
          <span *ngIf="isSaveDraft; else submitForApproval">
            Save Indent
          </span>
          <ng-template #submitForApproval>
            Submit For Approval
          </ng-template>
        </button>
      </div>
    </div>
  </div>
</section>
<ng-template  style="min-height:500px!important" #forConfirm>
  <mat-toolbar>
    <h3 mat-dialog-title>
      Confirmation
    </h3>
    <span class="spacer"></span>
  </mat-toolbar>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <p class="myp">{{confirmationMessage}}</p>
      </div>
      <div class="col">
        <div mat-dialog-actions>
          <button type="button" mat-raised-button (click)="confirm('confirm')">Confirm</button>
          <button type="button" mat-raised-button (click)="confirm('discard')">Discard</button>
        </div>
      </div>
    </div>
  </div>
</ng-template>