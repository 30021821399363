<!-- <a mat-list-item routerLink="." *ngFor="let nav of fillerNav">
  <i class="fab fa-firstdraft"></i>
</a> -->

<!-- <ul>
  <span *ngFor="let nav of arrNavigation">
    <li expandMenu mat-list class="active">
      <div class="navigation-container">
        <i class="{{nav.icon}}"></i><br>
        <p>{{nav.name}}</p>
      </div>
    </li>
    <ul class="submenu">
      <li *ngFor="let navc of nav.children" mat-list>
        <a mat-list-item [routerLink]="navc.routerLink" matTooltip="{{navc.desc}}">
          <div class="navigation-container">
            <i class="{{navc.icon}}"></i><br>
            <p>{{navc.name}}</p>
          </div>
        </a>
      </li>
    </ul>
  </span>
</ul> -->

<ul>
  <li mat-list>
    <div *ngFor="let nav of arrNavigation">
      <mat-expansion-panel>
        <mat-expansion-panel-header collapsedHeight="80px" expandedHeight="auto">
          <mat-list-item>
            <a style="cursor: pointer;" matTooltip="{{nav.desc}}">
              <div class="navigation-container">
                <i class="{{nav.icon}}"></i><br>
                <p>{{nav.name}}</p>
              </div>
            </a>
          </mat-list-item>
        </mat-expansion-panel-header>
        <mat-list>
          <div *ngFor="let navc of nav.children">
            <a mat-list-item [routerLink]="navc.routerLink" matTooltip="{{navc.desc}}" (click)="fireToggle($event)">
              <div class="navigation-container-child">
                <i class="{{navc.icon}}"></i><br>
                <p>{{navc.name}}</p>
              </div>
            </a>
          </div>
        </mat-list>
      </mat-expansion-panel>
    </div>
  </li>
</ul>

<!-- <a mat-list-item [routerLink]="nav.routerLink" matTooltip="{{nav.desc}}"></a> -->