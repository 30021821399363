import { Component, OnInit, Inject, Optional } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';

@Component({
  selector: 'app-bottom-sheet',
  templateUrl: './bottom-sheet.component.html',
  styleUrls: ['./bottom-sheet.component.css']
})
export class BottomSheetComponent implements OnInit {

  constructor(private _bottomSheetRef: MatBottomSheetRef<BottomSheetComponent>,
    @Optional() @Inject(MAT_BOTTOM_SHEET_DATA) public data: any) { 
      _bottomSheetRef.disableClose = false;
    }
    
  /* For budget validation */
  isBudgetValueAvailable: boolean;
  budgetName: string;
  draftValue: string;
  liableValue: string;
  isExceedsorExpired: string;

  /* For budget validation, after submit for approval */
  budgetValidationAfterSubmitForApproval: boolean;
  arrdetailMessageSubmitForApproval: BudgetValidationAfterSubmitForApproval[] = [];

  ngOnInit() {
    this.budgetValidationAfterSubmitForApproval = this.data.isFromSubmitForApproval;
    if(this.budgetValidationAfterSubmitForApproval){
       this.budgetValidationSubmit(this.data.data);
    } else {
      this.budgetName = this.data.budgetName;
      this.isBudgetValueAvailable = this.data.data.result.indexOf('FALL') > -1 ? true : false;
      this.isExceedsorExpired = this.data.data.result.indexOf('EXC') > -1 ? 'EXCEEDS' : this.data.data.result.indexOf('EXP') > -1 ? 'EXPIRED' : '';
      this.draftValue = this.data.data.result.split(',')[1];
      this.liableValue = this.data.data.result.split(',')[2];
    }
  }
  
  private budgetValidationSubmit(arrBudgetResult: Array<String>) {
  if(arrBudgetResult == null){
   this._bottomSheetRef.dismiss()
  }
    if(arrBudgetResult != null){
    if(arrBudgetResult.length != 0){
      arrBudgetResult.map(result => {
        let objBudgetValidation: BudgetValidationAfterSubmitForApproval = new BudgetValidationAfterSubmitForApproval();
        objBudgetValidation.budgetCode = result.split(',')[0];
        objBudgetValidation.reason = result.split(',')[1];
        objBudgetValidation.draftValue = result.split(',')[2];
        objBudgetValidation.liableValue = result.split(',')[3];
        objBudgetValidation.isExpiredorExceeds = objBudgetValidation.reason.indexOf('EX') > -1 ? true : false;
        this.arrdetailMessageSubmitForApproval.push(objBudgetValidation);
        console.log(this.arrdetailMessageSubmitForApproval);
      });
      }
    }
  }

  public closeBottomSheet() {
    this._bottomSheetRef.dismiss()
  }
}

export class BudgetValidationAfterSubmitForApproval {
  budgetCode: string;
  reason: string;
  draftValue: string;
  liableValue: string;
  isExpiredorExceeds: boolean;
}