import { Component, OnInit, Optional, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { RequesterNotification } from 'src/app/businessModules/department-indent/department-indent-model/department-indent.model';
import { SnackBarComponent } from 'src/app/shared/snack-bar/snack-bar.component';

@Component({
  selector: 'app-show-requester-value',
  templateUrl: './show-requester-value.component.html',
  styleUrls: ['./show-requester-value.component.css']
})
export class ShowRequesterValueComponent implements OnInit {

  constructor(
    @Optional() public dialogRef: MatDialogRef<ShowRequesterValueComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: RequesterNotification,
    private _snackBarComponent: SnackBarComponent) { }

  ngOnInit() {
console.info('%%%%%'+this.data.newValue);
console.info('%%%%%'+this.data.newValue);
  }

  /* Close the dialog box */
  public close() {
    this.dialogRef.close(0);
  }

}
