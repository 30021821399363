import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommonDataService {

  userName: string;
  userEmail: string;

  private isUserDetailsLoadedFromKeycloak = new BehaviorSubject(false);
  private userNameFromKeycloak = new BehaviorSubject('no user');
  private userEmailFromKeycloak = new BehaviorSubject('no email');
  private itemNotificationCount = new BehaviorSubject(0);
  private isUserHasRequesterRole = new BehaviorSubject(false); 

  /**
   * created observables too, for future use.
   */
  isUserDetailsLoadedFromKeycloakObs = this.isUserDetailsLoadedFromKeycloak.asObservable();
  keycloakUserNameObs = this.userNameFromKeycloak.asObservable();
  keycloakUserEmailObs = this.userEmailFromKeycloak.asObservable();
  itemNotificationCountObs = this.itemNotificationCount.asObservable();
  isuserHasRequesterRoleObs = this.isUserHasRequesterRole.asObservable();

  constructor() { }

  /**
   * got the user details from keycloak
   * @param gotUserDetails 
   */
  gotUserDetailsFromKeycloak(gotUserDetails: boolean){
    this.isUserDetailsLoadedFromKeycloak.next(gotUserDetails);
  }

  /**
   * change the default name to keycloak's user name
   * @param userName
   */
  changeUserName(userName: string) {
    this.userName = userName;
    this.userNameFromKeycloak.next(userName);
  }

  /**
   * change the default email to keycloak's email
   * @param email 
   */
  changeUserEmail(email: string) {
    this.userEmail = email;
    this.userEmailFromKeycloak.next(email);
  } 

  /**
   * fetch the latest value of item notification 
   * @param itemNotify 
   */
  fetchitemNotifyCount(itemNotify: number) {
    this.itemNotificationCount.next(itemNotify);
  }

  /**
   * to check if the notification screen is available
   * work around solution
   * need proper API to get the roles & check it.
   */
  notificationOnlyForRequester(userHasRequesterRole: boolean){
    this.isUserHasRequesterRole.next(userHasRequesterRole);
  } 
}
