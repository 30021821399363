<div class="container-fluid">

  <div class="title-box">
    <h3 mat-dialog-title>Add CC Mail ID</h3>
    <span class="spacer"></span>
    <mat-icon aria-label="close" (click)="onNoClick()" class="close">close</mat-icon>
  </div>

  <form #todoForm="ngForm">
    <mat-form-field>
      <input matInput 
             type="email" 
             #todo = "ngModel"
             placeholder="Email Id" 
             name="todo" 
             [(ngModel)]="todo.value"
             pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$">
      <mat-hint *ngIf="!todo.valid">Please enter a valid email</mat-hint>
    </mat-form-field>

    <button mat-icon-button color="primary">
        <button mat-raised-button color="primary" (click)="addTodo(todo.value)" [disabled]="loadingSpinner || !todo.valid" matTooltip="Add mail to the list">Add to list</button>
    </button>

    <div class="data">
      <ul class="list-unstyled">
        <li *ngFor="let todo of listEmail">
          <!-- <mat-checkbox (change)="onChange($event, todo)" [checked]="check"></mat-checkbox>  -->
          {{todo}}
          <i (click)="deleteItem(todo)" class="material-icons">delete</i>
        </li>
      </ul>
    </div>
    
  </form>

  <div mat-dialog-actions class="buttons">
      <!-- <button mat-raised-button (click)="consolidateMail()" [mat-dialog-close]="passData">Save & Close</button> -->
      <button mat-raised-button (click)="consolidateMail()">Save & Close</button>
      <!-- <button mat-raised-button [mat-dialog-close]="0">Cancel</button> -->
    </div>
</div>

<div class="spinner" *ngIf="loadingSpinner">
  <mat-spinner></mat-spinner>
</div>