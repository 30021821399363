import { Component, OnInit, Optional, Inject } from '@angular/core';
import { SnackBarComponent } from '../../snack-bar/snack-bar.component';
import { MatDialogRef, MAT_DIALOG_DATA, MatTableDataSource } from '@angular/material';
import {
  IndentCurrentStatusDetails,
  IndentRemarksDetails
} from 'src/app/businessModules/reports/indent-flow/indent-flow-model/indent-flow-model';

@Component({
  selector: 'app-remarks-display',
  templateUrl: './remarks-history-vdisplay.component.html',
  styleUrls: ['./remarks-history-vdisplay.component.css']
})
export class RemarksHistoryVDisplayComponent implements OnInit {

  constructor(
    @Optional() public dialogRef: MatDialogRef<RemarksHistoryVDisplayComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: IndentRemarksDetails[],
    private _snackBarComponent: SnackBarComponent) { }

  displayedColumns = ['Person', 'DateTime', 'Process', 'Action', 'Remarks'];
  dataSource: MatTableDataSource<IndentRemarksDetails>;

  ngOnInit() {
console.info(this.data);
for(let i=0;i<this.data.length;i++){
if(this.data[i]!=null){
if(this.data[i].remarks!=null){
console.info(this.data[i].remarks);
 let rem=this.data[i].remarks;
  while(rem.indexOf('LA VERIFIED')>0){
  rem=rem.replace('[LA VERIFIED]','');
  }
  while(rem.indexOf('ITEM VERIFIED')>0){
    rem=rem.replace('[ITEM VERIFIED]','');
  }
  while(rem.indexOf('SPEC VERIFIED')>0){
     rem=rem.replace('[SPEC VERIFIED]','');
  }
   while(rem.indexOf('IA ENTRY')>0){
       rem=rem.replace('[IA ENTRY]','');
  }
  while(rem.indexOf('IA-EDIT')>0){
       rem=rem.replace('[IA-EDIT]','');
  }
  while(rem.indexOf('REVERT')>0){
       rem=rem.replace('[REVERT]','');
  }

  this.data[i].remarks=rem;
  console.info(this.data[i].remarks);
  }
}
}
    this.dataSource = new MatTableDataSource(this.data);
  }

  /* Close the dialog box */
  public close() {
    this.dialogRef.close(0);
  }

}
