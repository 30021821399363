import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { RootAPIService } from '../rootService/rootApiService';
import { ActiveInactive, LedgerDetails, PurposeDetails, DepartmentDetails, EntityDetails, UserNavigation, AttachmentDetailsResponse } from './common.model';
import { BudgetItem } from 'src/app/businessModules/indent-draft/create-indent-draft/create-indent-draft-model/create-indent-draft.model';
import { ResourceDetails } from 'src/app/businessModules/authorisation/user-access/user-access-model/user-access.model';
import { RootPurchaseApiService } from '../rootService/rootPurchaseApiService';
import { PrintPurchaseOrder,DNPrintHeader,MRNHeaderReportDetails,PaymentReportParams, MRNReportParams} from 'src/app/businessModules/reports/indent-status-report/indent-status-report-model/indent-status-report.model';
@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(private _rootApiService: RootAPIService,
    private _rootPurchaseApiService: RootPurchaseApiService) { }

  getActiveInactive(): Observable<ActiveInactive[]> {
    const httpParams = new HttpParams();
    return this._rootApiService.GetData<ActiveInactive[]>('master/getConstant/activeInactive', httpParams)
  }

  getLedgerHeadFromMaster(isActive: string): Observable<LedgerDetails[]> {
    const httpParams = new HttpParams()
      .set('isActive', isActive);
    return this._rootApiService.GetData<LedgerDetails[]>('master/ledgerList', httpParams)
  }

   getLedgerHeadByBudget(isActive: string,budgetId: string): Observable<LedgerDetails[]> {
      const httpParams = new HttpParams()
        .set('isActive', isActive)
        .set('budgetId', budgetId);
      return this._rootApiService.GetData<LedgerDetails[]>('master/ledgerListByBudget', httpParams)
    }

  getPurposeFromMaster(isActive: string): Observable<PurposeDetails[]> {
    const httpParams = new HttpParams()
      .set('isActive', isActive);
    return this._rootApiService.GetData<PurposeDetails[]>('master/purposeList', httpParams)
  }

  getActiveDepartment(): Observable<DepartmentDetails[]> {
    const httpParams = new HttpParams();
    return this._rootApiService.GetData<DepartmentDetails[]>('master/getActiveDepartment', httpParams);
  }

    getActiveDepartmentView(): Observable<DepartmentDetails[]> {
      const httpParams = new HttpParams();
      return this._rootApiService.GetData<DepartmentDetails[]>('master/getActiveDepartmentView', httpParams);
    }

  getActiveEntity(): Observable<EntityDetails[]> {
    const httpParams = new HttpParams();
    return this._rootApiService.GetData<EntityDetails[]>('master/getActiveEntity', httpParams);
  }

  getBudgetDetails(deptId: string, entityId: string): Observable<BudgetItem[]> {
    const httpParams = new HttpParams()
      .set('deptId', deptId)
      .set('entityId', entityId);
    return this._rootApiService.GetData<BudgetItem[]>(`master/getBudgetCodeById`, httpParams);
  }

  getUserBasedDepartment(clientId: string, emailId: string): Observable<DepartmentDetails[]> {
    const httpParams = new HttpParams()
      .set('clientId', clientId)
      .set('emailId', emailId)
    return this._rootApiService.GetData<DepartmentDetails[]>('authorization/getUserDepartment', httpParams)
  }

  getNotificationCount(clientId: string, emailId: string, isCount: string): Observable<number> {
    const httpParams = new HttpParams()
      .set('clientId', clientId)
      .set('emailId', emailId)
      .set('isCount', isCount);
    return this._rootApiService.GetData<number>('notifications/getNotifications', httpParams);
  }

  getActiveResources(clientId: string, resourceType: string): Observable<ResourceDetails[]> {
    const httpParams = new HttpParams()
      .set('clientId', clientId)
      .set('resourceType', resourceType)
    return this._rootApiService.GetData<ResourceDetails[]>('authorization/getActiveResources', httpParams);
  }

  getUserNavigationList(clientId: string, emailId: string): Observable<UserNavigation> {
    const httpParams = new HttpParams()
      .set('clientId', clientId)
      .set('emailId', emailId)
    return this._rootApiService.GetData<UserNavigation>('authorization/getUserRole', httpParams);
  }

  /* For getting Attachments */
  getMrnAttachment(imageType: string, mrnId: string): Observable<AttachmentDetailsResponse[]> {
    let httpParam = new HttpParams().set("imageType", imageType).set("mrnId", mrnId);
    return this._rootPurchaseApiService.GetData<AttachmentDetailsResponse[]>('materialReceipt/getMrnAttachment', httpParam);
  };

  getPOAttachment(poNumber: string): Observable<PrintPurchaseOrder[]> {
          let httpParam = new HttpParams().set("poNumber", poNumber).set("print", "1");
          return this._rootPurchaseApiService.GetData<PrintPurchaseOrder[]>('purchaseOrder/getPOPrint', httpParam);
  };
  getMRNAttachment(imageType: string, mrnId: string): Observable<PrintPurchaseOrder[]> {
         const httpParams = new HttpParams();
          return this._rootPurchaseApiService.GetData<PrintPurchaseOrder[]>(`mrnprint/${mrnId}`, httpParams);
  };
  getDNAttachment(imageType: string, deliveryId: string): Observable<DNPrintHeader[]> {
          let httpParam = new HttpParams().set("imageType", imageType).set("deliveryId", deliveryId).set("detailed", "0");
          return this._rootPurchaseApiService.GetData<DNPrintHeader[]>('deliveryNote/getPrintHeader', httpParam);
  };

  getMRNReport(params: MRNReportParams): Observable<MRNHeaderReportDetails[]> {
    return this._rootPurchaseApiService.PostData<MRNHeaderReportDetails[],PaymentReportParams>('report/getMRNReport', params);
  }
    printPurchaseOrderDetails(poNumber: string): Observable<PrintPurchaseOrder[]> {
      let httpParam = new HttpParams().set("poNumber", poNumber).set("print", "1");
      return this._rootPurchaseApiService.GetData<PrintPurchaseOrder[]>('purchaseOrder/getPOPrint', httpParam);
    }


}
