<!-- ng-repeat="d in chunk" -->
<div size="A4" id="print-section">
  <br>
  <div align="center">
    <strong>Material Received Note - {{ddata}}</strong>
  </div>
  <br />

  <table width="100%" style="height: 150px;" border="0" class="maintd" align="center" cellspacing="0" cellpadding="0">
    <tr>
      <td colspan="2" align="center" style="height:50px;">
        <strong>Supplier Name :</strong>&nbsp;&nbsp;&nbsp;
        <span>{{data[0].supplierName}}</span>
      </td>
    </tr>
    <tr>
      <td colspan="2">
        <table id="mytable" width="100%" border="1" class="bfull" cellspacing="0" cellpadding="5">
          <tr>
            <td width="5%" align="center" valign="middle">SI No.</td>
            <td width="35%" align="center" valign="middle">Item</td>
            <td width="10%" align="center" valign="middle">Company / Department</td>
            <td width="9%" align="center" valign="middle">Indent No</td>

            <td width="6%" align="center" valign="middle">PO No</td>
            <td width="6%" align="center" valign="middle">Pending Qty</td>
            <td width="14%" align="center" valign="middle">Received Qty</td>
            <td width="14%" align="center" valign="middle">Balanced Qty</td>

            <td width="14%" align="center" valign="middle">Rate</td>
            <td width="14%" align="center" valign="middle">Tax %</td>
            <td width="14%" align="center" valign="middle">Discount %</td>
            <td width="14%" align="center" valign="middle">Remarks</td>
          </tr>
          <!-- need to be changed to *ngFor-->
          <tr *ngFor="let i of arrMrnItem">
            <td align="center" class="tabletwo"></td>
            <td>
              <b>{{i.itemName}}</b>
              <br />
              <span>{{i.itemSpecDesc}}</span>
            </td>
            <td align="left">{{i.compDeptName}}</td>
            <td align="right">{{i.indentNo}}</td>
            <td align="right">{{i.poId}}</td>

            <td align="right">
              <span>{{i.mrnDetailQty.toFixed(2)}} {{i.mrnDetailUom}}</span>
            </td>
            <td align="right">{{i.mrnReceivedQty.toFixed(2)}}</td>

            <td align="right">{{i.balanceQty.toFixed(2)}}</td>
            <td align="right">{{i.mrnDetailRate.toFixed(2)}}</td>
            <td align="right">{{i.mrnDetailTaxPercent}}</td>

            <td align="right">{{i.mrnDetailDiscountPercent}}</td>
            <td align="right">{{i.remarks}}</td>
          </tr>
        </table>
      </td>
    </tr>
    <tr>
      <td colspan="2">
        <br />
        <br />
        <!-- ng-show="{{$index+1==count}}" need to check it again -->
        <table width="100%" border="0" cellspacing="0" cellpadding="5" id="footer">
          <tr>
            <td>
              <strong>Supplier Bill Number</strong>
            </td>
            <td>
              <span>{{data[0].supplierBillNo}}</span>
            </td>
            <td>
              <strong>Supplier Bill Date</strong>
            </td>
            <td>
              <span>{{data[0].supplierBillDate}}</span>
            </td>
          </tr>
          <tr>
            <td>
              <strong>Freight</strong>
            </td>
            <td>
              <span>{{data[0].mrnFrieghtCharges.toFixed(2)}}</span>
            </td>
            <td>
              <strong>Round Off Amount</strong>
            </td>
            <td>
              <span>{{data[0].roundOff.toFixed(2)}}</span>
            </td>
          </tr>
          <tr>
            <td>
              <strong>Discount Amount</strong>
            </td>
            <td>
              <span>{{data[0].mrnDiscount.toFixed(2)}}</span>
            </td>
            <td>
              <strong>Tax Amount</strong>
            </td>
            <td>
              <span>{{data[0].mrnTax.toFixed(2)}}</span>
            </td>
          </tr>
          <tr>
            <td>
              <strong>Misc charge</strong>
            </td>
            <td>
              <span>{{data[0].mrnMiscCharges.toFixed(2)}}</span>
            </td>
            <td>
              <strong>Supplier Bill Amount</strong>
            </td>
            <td>
              <span>{{data[0].supplierBillAmount.toFixed(2)}}</span>
            </td>
          </tr>
        </table>
        <!-- <div align="right" ng-hide="{{$index+1==count}}">please turn over</div> -->
      </td>
    </tr>
  </table>
</div>