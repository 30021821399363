<section class="notification-section">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="notification-header">
          <h1>Notification</h1>
        </div>
        <div class="notification-content">
          <!--<div class="item">
            <p>
                <span matBadge="{{itemNotificationCount}}" matBadgeOverlap="false" matBadgeColor="warn" [matBadgeHidden]="itemNotificationCount === 0">Item action</span>
            </p>
            <div class="spacer"></div>
            <button mat-icon-button routerLink="/department-indent/requester-notification">
                <mat-icon>arrow_back</mat-icon>
            </button>
          </div>-->
          <mat-divider></mat-divider>
          <div class="fc">
            <p>
                <span matBadge="{{maNotificationCount}}" matBadgeOverlap="false" matBadgeColor="warn" [matBadgeHidden]="maNotificationCount === 0">MA action </span> 
            </p>
            <div class="spacer"></div>
            <button mat-icon-button routerLink="/approvals/material-acknowledgement">
                <mat-icon>arrow_back</mat-icon>
            </button>
          </div>
          <mat-divider></mat-divider>
          <div class="ma">
            <p>
                <span matBadge="{{fcNotificationCount}}" matBadgeOverlap="false" matBadgeColor="warn" [matBadgeHidden]="fcNotificationCount === 0">FC action </span>
            </p>
            <div class="spacer"></div>
            <button mat-icon-button  routerLink="/approvals/final-clearance">
                <mat-icon>arrow_back</mat-icon>
            </button>
          </div>
          <mat-divider></mat-divider>
        </div>
      </div>
    </div>
  </div>
</section>