export class MaterialAcknowledgementList {
    indentNo: string;
    itemName: string;
    specDesc: string;
    mrndId: number;
    dnId: number;
    dndId: number;
    indentDetailId: number;
    indentQty: number;
    mrnQty: number;
    dnQty: number;
    rejectionProcessDNQty: number;
    purchaseRejectionQty: number;
    approvedQty: number;
    rejectionQty: number;
    receivedBy: string;
    receivedDate: number;
    rejectionRemarks: string;
    deptName: string;
    deptId: number;
    mrnId: number;
    action: number;
    status: string;
    isEditable: number;
    billName: string;
    disableApprovalField: boolean;
    billUom: string;
    indentUom: string;
  }

  export class SaveMaterialAcknowledgement {
    dndId: number;
    dnId: number;
    mrnId: number;
    mrndId: number;
    deptId: number;
    dnQty: number;
    approvedQty: number;
    rejectedQty: number;
    dnApprovalRemark: string;
    action: number;
    loggedUser: string;
  }

  export class MailInfo {
    mailInfo: MailInfoArr[];
    isValueRange: number;
    swapMailList: string[];
  }
  
  export class MailInfoArr {
    primaryEmailId: string;
    ccMailIds: string[];
    processType: string;
    fromValue: number;
    toValue: number;
    status: number;
    Name: string;
  }
  
  export class SwapItem {
    deptId: number;
    primaryMailId: string;
    fromValue: number;
    toValue: number;
    fromDate: string;
  }
  
  export class MailInfoByTypeRequest {
    deptId: number;
    processType: string;
    userRole: string;
  }
  
  export class SaveSwap {
    deptId: number;
    fromDate: string;
    fromValue: number;
    toValue: number;
    primaryMailId: string;
    processType: string;
    swapId: number;
  }
  