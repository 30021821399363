import { Injectable } from '@angular/core';
import { RootPurchaseApiService } from 'src/app/shared/rootService/rootPurchaseApiService';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { RootAPIService } from 'src/app/shared/rootService/rootApiService';
import { MaterialAcknowledgementList, SaveMaterialAcknowledgement, MailInfo } from '../ma-model/ma.model';
import { DepartmentDetails, PurchaseResponse } from 'src/app/shared/commonService/common.model';

@Injectable({
  providedIn: 'root'
})
export class MaService {

  constructor(private _rootApiService: RootAPIService,
    private _rootPurchaseApiService: RootPurchaseApiService) { }

  // Material Acknowledgement --> Department List
  getDepartmentBasedUser(userEmailId: string, userRole: string, processType: string): Observable<DepartmentDetails[]> {
    let httpParams: HttpParams = new HttpParams()
      .set("userEmailId", userEmailId)
      .set("userRole", userRole)
      .set("processType", processType);
    return this._rootPurchaseApiService.GetData<DepartmentDetails[]>("dnApproval/getUserDept",
      httpParams
    );
  }

  // Material Acknowledgement  --> item list based on department
  getDnApprovalByDept(deptId: string, mrnId: string, userRole: string): Observable<MaterialAcknowledgementList[]> {
    let httpParams: HttpParams = new HttpParams()
      .set("deptId", deptId)
      .set("mrnId", mrnId)
      .set("userRole", userRole);
    return this._rootPurchaseApiService.GetData<MaterialAcknowledgementList[]>("dnApproval/getDnApprovalByDept", httpParams);
  }

  // Material Acknowledgement --> List of Active swaps scheduled
  getActiveSwapList(swapDataRequest): Observable<any> {
    return this._rootPurchaseApiService.PostData<PurchaseResponse, any>("listMasters/activeSwapDetail", swapDataRequest);
  }

  // Material Acknowledgement --> List of CC emails
  getDeptCCEmails(mailInfoByType): Observable<any> {
    return this._rootPurchaseApiService.PostData<MailInfo, any>("listMasters/mailInfoByType", mailInfoByType);
  }

  // Material Acknowledgement --> List of Active swaps scheduled
  saveSwap(swapData: any): Observable<any> {
    return this._rootPurchaseApiService.PostData<DepartmentDetails, any>("quickAdd/saveSwapDetails", swapData);
  }

  // Save Material Acknowledgement
  saveDnApprovalItemByDept(SaveMaterialAcknowledgement: SaveMaterialAcknowledgement[]): Observable<PurchaseResponse> {
    return this._rootPurchaseApiService.PostData<PurchaseResponse, SaveMaterialAcknowledgement[]>("dnApproval/saveDnApprovalItemByDept", SaveMaterialAcknowledgement);
  }
}
