<mat-toolbar color="primary">
  <span>Swap Mail ID</span>
  <span class="spacer"></span>
  <div class="form form-width">
      <mat-form-field color="accent">
          <mat-select placeholder="Department" [formControl]="dept" name="department" (selectionChange)="departmentChange()"
              name="department">
              <mat-option *ngFor="let depts of departments" [value]="depts.deptId">
                  {{ depts.deptName }}
              </mat-option>
          </mat-select>
      </mat-form-field>
  </div>
  <div class="form form-width" [hidden]="isValueRangeEnable">
      <mat-form-field>
          <mat-select placeholder="Value Range" [formControl]="valueRanges" (change)="fcfetchActiveUser($event)">
              <mat-option *ngFor="let value of arrvalueRange" [value]="value">
                  {{ value }}
              </mat-option>
          </mat-select>
      </mat-form-field>
  </div>
  <mat-icon aria-label="close" (click)="onNoClick()" class="close">close</mat-icon>
</mat-toolbar>

<form [formGroup]="swapFormControl" (ngSubmit)="onSubmit(swapFormControl.value)">
  <ng-container *ngIf="deptId else chooseDepartment">
      <ng-container *ngTemplateOutlet="showForm ? swapForm : swapList">
      </ng-container>
  </ng-container>

  <ng-template #chooseDepartment>
      <br />
      <div style="text-align:center">
          Please select department from the dropdown.
      </div>
  </ng-template>

  <ng-template #swapForm>
      <!--Swap Date & CC, primary Ids-->
      <div class="container">
          <div class="row">
              <div class="col-md-4">
                  <div class="form form-width">
                      <mat-form-field color="accent">
                          <input matInput [min]="minDate" [matDatepicker]="picker" placeholder="From Date"
                              formControlName="fromDate" [errorStateMatcher]="matcher">
                          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                          <mat-datepicker #picker (selectedChanged)="updateDateToString($event)"></mat-datepicker>
                          <mat-error>
                              From Date is required
                          </mat-error>
                      </mat-form-field>
                  </div>
              </div>
              <!--<div class="col-md-4" [hidden]="isValueRangeEnable">
           <mat-form-field>
                  <mat-select placeholder="Value Range"  formControlName="valueRange">
                      <mat-option *ngFor="let value of arrvalueRange" [value]="value">
                          {{ value }}
                      </mat-option>
                  </mat-select>
              </mat-form-field>
          </div> -->
              <div class="col-md-4">
                  <mat-form-field>
                      <mat-select placeholder="Primary mail" formControlName="primaryMailId" [errorStateMatcher]="matcher">
                          <mat-option *ngFor="let id of primaryMailIdOptions" [value]="id">
                              {{ id }}
                          </mat-option>
                      </mat-select>
                      <mat-error>
                          Email address is required
                      </mat-error>
                  </mat-form-field>
              </div>
              <div class="col-md-4">

              </div>

          </div>
      </div>

      <div mat-dialog-actions class="buttons">
          <button mat-raised-button [disabled]="swapFormControl.invalid">Save swap</button>
      </div>
  </ng-template>

  <ng-template #swapList>
      <div class="example-container mat-elevation-z8">
          <mat-table #table [dataSource]="activeSwapList">

              <ng-container matColumnDef="fromDate">
                  <mat-header-cell *matHeaderCellDef> From </mat-header-cell>
                  <mat-cell *matCellDef="let element"> {{element.fromDate}} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="primaryMailId">
                  <mat-header-cell *matHeaderCellDef> Primary Email </mat-header-cell>
                  <mat-cell *matCellDef="let element"> {{element.primaryMailId}} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="actions">
                  <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>

                  <mat-cell *matCellDef="let row; let i=index;">
                      <button mat-icon-button color="accent" (click)="editSwap(row)">
                          <mat-icon aria-label="Edit">edit</mat-icon>
                      </button>
                  </mat-cell>
              </ng-container>
              <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
          </mat-table>
      </div>

      <div mat-dialog-actions class="buttons">
          <button mat-raised-button [disabled]="loadingSpinner" (click)="editSwap(null)">Schedule Swap</button>
      </div>
  </ng-template>
</form>
<div class="spinner" *ngIf="loadingSpinner">
  <mat-spinner></mat-spinner>
</div>
