import { Component, OnInit, Optional, Inject } from '@angular/core';
import { SnackBarComponent } from '../../snack-bar/snack-bar.component';
import { MatDialogRef, MAT_DIALOG_DATA, MatTableDataSource } from '@angular/material';
import { IndentCurrentStatusDetails } from 'src/app/businessModules/reports/indent-flow/indent-flow-model/indent-flow-model';

@Component({
  selector: 'app-remarks-display',
  templateUrl: './remarks-display.component.html',
  styleUrls: ['./remarks-display.component.css']
})
export class RemarksDisplayComponent implements OnInit {

  constructor(
    @Optional() public dialogRef: MatDialogRef<RemarksDisplayComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: IndentCurrentStatusDetails[],
    private _snackBarComponent: SnackBarComponent) { }

  displayedColumns = ['role', 'dateTime', 'person', 'remarks'];
  dataSource: MatTableDataSource<IndentCurrentStatusDetails>;

  ngOnInit() {
    this.dataSource = new MatTableDataSource(this.data);
  }

  /* Close the dialog box */
  public close() {
    this.dialogRef.close(0);
  }

}
