export class IndentReportDetails {
    sno: string;
    categoryName: string;
    indentDate: string;
    compDeptName: string;
    indentNo: string;
    itemName: string;
    itemSpec: string;
    indentQtyUom: string;
    paymentType: string;
    statusName: string;
    budgetName: string;
    purpose: string;
    ledgerHead: string;
    indentApproval: number;
    quoteEntry: number;
    billNumbers: string;
  
    quoteApproval: number;
    quoteApprovalStr: string;
  
    purchaseOrder: number;
    purchaseOrderStr: string;
  
    poStatus: string;
    materialReceipt: number;
    materialReceiptStr: string;
  
    deliveryNote: number;
    deliveryNoteStr: string;
  
    returnNote: number;
    purchaseOrderRef: string;
    arrPurchaseOrderRef: string[];
    purchaseOrderRefpoStatus: string;
    materialReceiptRef: string;
    arrMaterialReceiptRef: string[];
    deliveryNoteRef: string;
    arrDeliveryNoteRef: string[];
    dnUnitPrices: string;
    mrnTax: number | string;
    supplierNames: string;
    remarks: string;
    arrBillNames: string[];
    arrMrnIds: string[];
  }

  export class IndentReportParams {
    fromDate: string;
    toDate: string;
    itemId: string;
    categoryId: string;
    deptId: string;
    indentNo: string;
    statusName: string;
  }

  export class CategoryInfo {
    categoryDesc: string;
    categoryId: number;
    status: number;
    statusName: string;
    categoryName: string;
    autoAppvTime: number;
  }


export class PrintPurchaseOrder {
    companyId: number;
    deptId: number;
    supplierId: number;
    indentNos: string;
    companyName: string;
    companyPrintName: string;
    deptName: string;
    deptPrintName: string;
    companyAddress: string;
    companyAddress1: object;
    companyCity: object;
    companyState: object;
    companyPostalCode: object;
    poDate: string;
    supplierName: string;
    supplierPrintName: string;
    supplierAddress: string;
    supplierAddress1: string;
    city: string;
    stateProvinceRegion: string;
    postalCode: string;
    gstin: string;
    grandTotal: string;
    poDiscount: number;
    poTax: number;
    poFrieght: number;
    poMiscCharge: number;
    remarks: string;
    dispatchThru: string;
    modeOfPayment: string;
    termsOfDelivery: string;
    destinationAddress: string;
    poId: number;
    poPrintDetail: PrintPurchaseOrderDetails[];

    rupees: string;
    paise: string;
    rupeersInWords: string;
    poCreatedBy: string;
 }

export class DNPrintHeader {
    deliveryId: number;
    companyName: string;
    deptName: string;
    compDeptId: string;
    compDeptName: string;
    deliveryDate: string;
    deliveryTakenBy: string;
    deliveryTakerMobNo: string;
    deliveryTakenDatetime: string;
    deliveryComment: string;
    mrnId: string;
    deliveryStatus: number;
    deldetail: DNPrintDetail[];

    rupeersInWords: string;
    grandTotal: number;
}

export class DNPrintDetail {
    deliveryId: number;
    deliveryDetailId: number;
    mrnDetailId: number;
    mrnId: number;
    prDetailId: number;
    itemName: string;
    poId: string;
    indentNo: string;
    itemId: number;
    itemSpecDesc: string;
    deliverQty: number;
    remarks: string;
    status: string;
    deliverQtyUom: string;
    supplierBillNo: string;
    totalRate: number;
    indentQty: number;
    indentUom: string;
    itemSpecId: number;
    instock: number;
    balanceQty: number;
    mrnReceivedQty: number;
    poCashType: string;
    supplierNames: string;
    supplierId: number;
    purchaseRejectionQty: number; // New
    purchaseRejectionRemarks: string; //New
    action: number;
    mrnQty: number;
    billUom : string;
    mrnDetailUom: string;
    dnStatus: string;
}

export class PrintPurchaseOrderDetails {
    sno: number;
    poId: number;
    poDetailId: number;
    prDetailId: number;
    indentNo: string;
    poaId: number;
    pismId: number;
    updateable: number;
    itemName: string;
    itemId: number;
    itemSpecId: number;
    itemCategoryId: number;
    poDetailItemSpec: string;
    poUom: string;
    prDetailItemUom: string;
    poPrintUom: string;
    poQty: number;
    poPurgeQty: number;
    poRate: number;
    poDiscountPercent: number;
    poTaxPercent: number;
    remarks: string;
    poStatus: string;

    netAmount : string;
}

export class SupplierInfo {
    sno: string;
    supplierId: number;
    supplierName: string;
    supplierAddress: string;
    supplierAddress1: string;
    city: string;
    stateProvinceRegion: string;
    postalCode: string;
    supplierMobile: string;
    supplierEmail: string;
    supplierCstNo: string;
    supplierTinNo: string;
    paymentType: number;
    paymentName: string;
    bankAccName: string;
    bankAccNo: string;
    bankBranch: string;
    bankIfscCode: string;
    itemCategoryId: string;
    itemCategoryName: string;
    printName: string;
    remarks: string;
    status: number;
    statusName: string;
}

export class MRNReportParams {
  deptId: string;
  supplierId: string;
  fromDate: string;
  toDate: string;
  mrnId: string;
}

export class MRNHeaderReportDetails {
  sno: string;
  mrnId: number;
  supplierId: number;
  supplierName: string;
  mrnDate: string;
  mrnBy: string;
  billApprovalTime: string;
  supplierBillNo: string;
  supplierBillDate: string;
  supplierBillNoDate: string;

  supplierBillAmount: number;
  supplierBillAmountStr: string;

  mrnFrieghtCharges: number;
  mrnFrieghtChargesStr: string;

  roundOff: number;
  roundOffStr: string;

  mrnDiscount: number;
  mrnDiscountStr: string;

  mrnTax: number;
  mrnTaxStr: string;

  mrnMiscCharges: number;
  mrnMiscChargesStr: string;

  mrnItemReport: MRNDetailReportDetails[];
}

export class MRNDetailReportDetails {
  sno: string;
  mrnId: number; // Need to be check
  supplierId: number; // Need to be check
  supplierName: string; // Need to be check
  mrnDate: string; // Need to be check

  supplierBillNo: string; // Need to be check
  supplierBillDate: string; // Need to be check
  supplierBillNoDate: string; // Need to be check

  supplierBillAmount: number; // Need to be check
  supplierBillAmountStr: string; // Need to be check

  mrnFrieghtCharges: number; // Need to be check
  mrnFrieghtChargesStr: string; // Need to be check

  roundOff: number; // Need to be check
  roundOffStr: string; // Need to be check

  mrnDiscount: number; // Need to be check
  mrnDiscountStr: string; // Need to be check

  mrnTax: number; // Need to be check
  mrnTaxStr: string; // Need to be check

  mrnMiscCharges: number; // Need to be check
  mrnMiscChargesStr: string; // Need to be check

  compDeptPrintName: string;
  indentQty: string;
  mrnDetailId: number;
  indentNo: string;
  compDeptName: string;
  compDeptId: string;
  poDetailId: number;
  poId: number;
  prDetailId: number;
  itemId: number;
  itemName: string;
  itemNameSpecDesc: string;
  itemSpecId: number;
  itemSpecDesc: string;
  mrnReceivedQty: number;

  mrnDetailRate: number;
  mrnDetailRateStr: string;

  mrnDetailDiscountPercent: number;
  mrnDetailDiscountPercentStr: string;

  mrnDetailTaxPercent: number;
  mrnDetailTaxPercentStr: string;

  mrnDetailQty: number;
  mrnDetailUom: string;
  remarks: string;

  balanceQty: number;
  mrnBy: string;
}

export class PaymentReportParams {
  supplierId: string;
  fromDate: string;
  toDate: string;
}
