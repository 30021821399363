import { Component, ElementRef, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { SnackBarComponent } from 'src/app/shared/snack-bar/snack-bar.component';
import {  Optional, Inject } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef, MatSelectChange} from '@angular/material';

import { PrintPurchaseOrder } from 'src/app/businessModules/reports/indent-status-report/indent-status-report-model/indent-status-report.model';
import { Subscription } from 'rxjs';

import { CommonService } from 'src/app/shared/commonService/common.service';
@Component({
  selector: 'app-po-print',
  templateUrl: './po-print.component.html',
  styleUrls: ['./po-print.component.css']
})

export class PoPrintComponent implements OnInit {

  //arrPoPrint: PrintPurchaseOrder[] = [];
  data: PrintPurchaseOrder[] = [];
  listSubscription : Subscription[] = [];
  userName: string = "";
aaa:string ='radha';
  @ViewChild('content') po : ElementRef;
   constructor(private route: ActivatedRoute,
      public dialogRef: MatDialogRef<PrintPurchaseOrder>,
    private router: Router,
    @Optional() @Inject(MAT_DIALOG_DATA) public ddata: any,

    private snackBarComponent: SnackBarComponent,


    private _commonService: CommonService) {

     }

  ngOnInit() {
    this.getPoPrint();
    this.userName = 'xxx';
    this.aaa='eeeeeeeeeeeeeeee';
  }


  /* retrieving the data for PO print */
  getPoPrint(): void {
    let poNumber = this.ddata;
    console.log('PO number : ', poNumber);

    let SubscribePoPrint : Subscription = this._commonService.printPurchaseOrderDetails(this.ddata)
          .subscribe(response => {
            console.log('response from the API : ', response);
            this.data = response;

            this.data.map(item => {
            console.info(this.data[0].companyPrintName);

              //item.rupeersInWords = SnackBarComponent.ChangeAmountToWordsWithPaise(item.grandTotal);
              //console.log('rupeersInWords : ', item.rupeersInWords);
            })

            this.data[0].poPrintDetail.map(item => {
              let total: number = ((item.poQty) * (item.poRate));
              let discountedval: number = total - ((total * item.poDiscountPercent) / 100);
              let final: number = discountedval + ((discountedval * item.poTaxPercent) / 100);

              item.netAmount = (final).toFixed(2);
            });

            setTimeout(() => {
              let printContents, popupWin;
              printContents = document.getElementById('content').innerHTML;
              popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
              popupWin.document.open();
              popupWin.document.write(`
          <html>
            <head>
              <title>Print tab</title>
              <style>
              .remarks {
                word-wrap: break-word;
                display: block;
                max-width: 680px;
            }

            .address {
                word-wrap: break-word;
                max-width: 240px;
            }

            div[size="A4"] {
                background: white;
                width: 21cm;
                min-height: 30.4cm;
                display: block;
                margin: 0 auto;
                position: relative;
                margin-bottom: 0.5cm;
                box-shadow: 0 0 0.5cm rgba(0,0,0,0.5);
            }

            .bleft {
                border-left: solid 1px #666666;
            }

            .bright {
                border-right: solid 1px #666666;
            }

            .btop {
                border-top: solid 1px #666666;
            }

            .bbottom {
                border-bottom: solid 1px #666666;
            }

            .maintd {
                width: 700px;
                border: solid 1px #666666;
            }

            .bfull {
                border: solid 1px #666666;
                border-collapse: collapse;
            }

            @media print {
                body, div[size="A4"] {
                    margin: 0;
                    box-shadow: 0;
                }

                .maintd {
                    width: 1000px;
                    border: solid 1px #666666;
                }
            }
              </style>
            </head>
            <body onload="window.print();window.close()">${printContents}</body>
          </html>`
              );
              popupWin.document.close();
            }, 1500);


          }, (error: HttpErrorResponse) => {
           // this.exceptionHandlingService.throwException(error);
          });
          this.listSubscription.push(SubscribePoPrint);
  }

  ngOnDestroy(): void {
    this.listSubscription.map(subscribe => {
      subscribe.unsubscribe();
    });

  }
}
