<mat-toolbar class="componentToolbar">
  <h1>Remarks List</h1>
  <span class="spacer"></span>
  <mat-icon aria-label="close" (click)="close()" class="close">close</mat-icon>
</mat-toolbar>

<section>
  <div class="box-shadow">
    <table mat-table [dataSource]="dataSource">

      <ng-container matColumnDef="role">
        <th mat-header-cell *matHeaderCellDef>Role</th>
        <td mat-cell *matCellDef="let row"> {{row.role}} </td>
      </ng-container>

      <ng-container matColumnDef="dateTime">
        <th mat-header-cell *matHeaderCellDef>Date</th>
        <td mat-cell *matCellDef="let row"> {{row.approvedAt}}</td>
      </ng-container>

      <ng-container matColumnDef="person">
        <th mat-header-cell *matHeaderCellDef>User</th>
        <td mat-cell *matCellDef="let row"> 
            <span *ngIf="row.approvalStatus === 'OPEN'; else others">
                {{row.approvalPerson}} 
                <br> <span class="subText">is yet to approve</span>
              </span>
              <ng-template #others>
                {{row.approvedBy}}
              </ng-template>
        </td>
      </ng-container>

      <ng-container matColumnDef="remarks">
        <th mat-header-cell *matHeaderCellDef>Remarks</th>
        <td mat-cell *matCellDef="let row">
          <span *ngIf="row.remarks === null; else remarksAvailable">
            &nbsp; - &nbsp;
          </span>
          <ng-template #remarksAvailable>
              {{row.remarks}}
          </ng-template>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky:true;"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;">
      </tr>
    </table>
  </div>
</section>