<!-- <mat-icon aria-label="close" (click)="closeBottomSheet()">close</mat-icon> -->

<section *ngIf="!budgetValidationAfterSubmitForApproval; else submitForApproval">
<!-- This has to be made into one (refactoring) using ngClass -->
  <div *ngIf="isBudgetValueAvailable; else exceeds">
    <div class="validation">
      Your total based on <i>{{ budgetName }}</i> <br> <span class="available">FALL BEHIND</span> the budget value <br>
      {{draftValue}} <br>
      {{liableValue}}
    </div>
  </div>
  <ng-template #exceeds>
    <div class="validation">
      Your total based on <i>{{ budgetName }}</i> <br> <span class="exceeds">{{isExceedsorExpired}}</span> the budget Value <br>
      {{draftValue}} <br>
      {{liableValue}}
    </div>
  </ng-template>
</section>

<ng-template #submitForApproval>
  <div class="submit_budget_validation">
    <div *ngFor="let budgetresult of arrdetailMessageSubmitForApproval">
      <ul>
        <li>
          <mat-icon>keyboard_arrow_right</mat-icon><strong>{{budgetresult.budgetCode}}</strong> &nbsp; <span [ngClass]="[budgetresult.isExpiredorExceeds === true ? 'exceeds' : 'available']">{{budgetresult.reason}}</span> &nbsp; {{budgetresult.draftValue}} &nbsp; {{budgetresult.liableValue}}</li>
      </ul>
    </div>
  </div>
</ng-template>