<div class="title-box">
  <h3 mat-dialog-title>Purchase Order Print</h3>
  <span class="spacer"></span>
  <mat-icon aria-label="close"  class="close">close</mat-icon>
</div>
<div size="A4" id="content">
  <div align="center">
    <strong>PURCHASE ORDER</strong>
  </div>

  <table width="100%" style="height:150px;" border="0" class="maintd" align="center" cellspacing="0" cellpadding="0">
    <tr>
      <td>
        <table width="100%" border="0" cellspacing="0" cellpadding="0">
          <tr>
            <td width="44.7%" valign="top">
              <table width="100%" border="0" cellspacing="0" cellpadding="5">
                <tr style="height:139px;">
                  <td class="bbottom bright">Invoice To
                    <div>
                      <br />
                      <b>{{data[0].companyPrintName}}</b>
                      <br /> {{data[0].companyAddress}}
                      <br /> {{data[0].companyAddress1}}
                      <br /> {{data[0].companyCity}}
                      <br /> {{data[0].companyState}}
                      <br /> {{data[0].companyPostalCode}}
                      <br /> <strong> GST No. : {{data[0].gstin}} </strong>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td valign="top" class="bright" style="height:150px">Supplier
                    <div style="padding-left:15px;max-width:400px;display:block;word-wrap:break-word;">
                      <br />
                      <b style="margin:0px">{{data[0].supplierPrintName}}</b><br>
                      {{data[0].supplierAddress}}
                      <br> {{data[0].supplierAddress1}}
                      <br> {{data[0].city}}
                      <br> {{data[0].postalCode}}
                    </div>
                  </td>
                </tr>
              </table>
            </td>
            <td width="50%" valign="top">
              <table width="100%" border="0" cellspacing="0" cellpadding="0">
                <tr>
                  <td>
                    <table width="100%" border="0" cellspacing="0" cellpadding="5">
                      <tr>
                        <td style="width:150px;" class=" bright bbottom">Date
                          <br />
                          <strong>{{data[0].poDate}}</strong>
                        </td>

                        <td class="bbottom">Mode/Terms of Payment
                          <br />
                          <strong>{{data[0].modeOfPayment}} </strong>
                        </td>
                      </tr>

                      <tr>
                        <td style="height:42px;overflow:auto;" class="bright bbottom">Purchase Order No
                          <br />
                          <strong>{{data[0].poId}}</strong>
                        </td>
                        <td valign="top" class="bbottom">Indent No / Ref No
                          <br />
                          <strong>{{data[0].indentNos}} </strong>
                        </td>
                      </tr>
                      <tr>
                        <td style="height:32px;overflow:auto;" valign="top" class="bright bbottom">Dispatch through
                          <br />
                          <strong>{{data[0].dispatchThru}}</strong>
                        </td>
                        <td valign="top" class="bbottom address">Destination
                          <br />
                          <strong> {{data[0].destinationAddress}}</strong>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td valign="top">Terms of Delivery
                    <br />
                    <strong>{{data[0].termsOfDelivery}}</strong>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </table>
      </td>
    </tr>
    <tr>
      <td>
        <table id="mytable" width="100%" border="1" class="bfull" cellspacing="0" cellpadding="5">
          <tr>
            <td width="5%" align="center" valign="middle">SI No.</td>
            <td width="35%" align="center" valign="middle">Description of Goods</td>
            <td width="10%" align="center" valign="middle">Quantity</td>
            <td width="9%" align="center" valign="middle">Unit Price</td>
            <td width="6%" align="center" valign="middle">Tax %</td>
            <td width="6%" align="center" valign="middle">Disc. %</td>
            <td width="14%" align="center" valign="middle">Amount</td>
          </tr>

          <!-- ng-repeat="i in d.JobId" -->
          <tr *ngFor = "let i of data[0].poPrintDetail">
            <td align="center">{{i.sno + 1}}</td>
            <td>
              <strong>{{i.itemName}}</strong>
              <br />
              <span>{{i.poDetailItemSpec}}</span>
              <br />
              <!-- *ngIf="validateRemarks(i.remarks)" -->
              <!-- <strong>Add.Info</strong>  -->
              <span ><!-- *ngIf="validateRemarks(i)" -->
                  <strong>Add.Info</strong>{{i.remarks}}
              </span>
            </td>
            <td align="left">
              <strong>{{i.poQty}} {{i.poPrintUom}}</strong>
            </td>
            <td align="right">{{i.poRate.toFixed(2)}}</td>
            <td align="right">{{i.poTaxPercent}}</td>
            <td align="right">{{i.poDiscountPercent}}</td>
            <td align="right">{{i.netAmount}}</td>

          </tr>

          <tr>
            <td colspan="2" style="border:none;">&nbsp;</td>
            <td align="right" colspan="4">Discount Amount</td>
            <td align="right">{{data[0].poDiscount.toFixed(2)}}</td>
          </tr>
          <tr>
            <td colspan="2" style="border:none;">&nbsp;</td>
            <td align="right" colspan="4">Tax Amount</td>
            <td align="right">{{data[0].poTax.toFixed(2)}}</td>
          </tr>
          <tr>
            <td colspan="2" style="border:none;">&nbsp;</td>
            <td align="right" colspan="4">Frieght</td>
            <td align="right">{{data[0].poFrieght.toFixed(2)}}</td>
          </tr>
          <tr>
            <td colspan="2" style="border:none;">&nbsp;</td>
            <td align="right" colspan="4"> Misc charge</td>
            <td align="right">{{data[0].poMiscCharge.toFixed(2)}}</td>
          </tr>

          <tr>
            <td colspan="2" style="border:none;">&nbsp;</td>
            <td align="right" colspan="4">Total</td>
            <td align="right">&#8377;
              <strong>
                {{data[0].grandTotal}}
              </strong>
            </td>
          </tr>
        </table>
      </td>
    <tr>
      <td>
        <table width="100%" border="0" cellspacing="0" cellpadding="5">
          <tr>
            <td width="42%">Amount Chargeable (in words)</td>
            <td width="58%" align="right">E. &amp; O. E</td>
          </tr>
          <tr>
            <td colspan="2">
              <b>{{data[0].rupeersInWords}}</b>
            </td>
          </tr>
          <tr>
            <td colspan="2" style="height:140px;">&nbsp;</td>
          </tr>
          <tr>
            <td colspan="2">Remarks:
              <br/>
              <strong class="remarks">
                {{data[0].remarks}}</strong>
            </td>
          </tr>
          <tr>
            <td align="left">PO generated by <br>
              {{data[0].poCreatedBy}}</td>
            <td align="right" valign="top" class="btop bleft" style="height:50px;">
              <b>for {{data[0].companyPrintName}}</b>
            </td>
          </tr>
          <tr>
            <td>&nbsp;</td>
            <td align="right" class="bleft">Authorised Signatory</td>
          </tr>
        </table>
      </td>
    </tr>
  </table>
</div>
<!-- </page> -->


<!-- <table width="100%" style="height:150px;" border="0" class="maintd" align="center" cellspacing="0" cellpadding="0">
    <tr>
      <td>
        <table width="100%" border="0" cellspacing="0" cellpadding="0">
          <tr *ngIf="$index==0">
            <td width="44.7%" valign="top">
              <table width="100%" border="0" cellspacing="0" cellpadding="5">

                <tr style="height:139px;">
                  <td class="bbottom bright">Invoice To

                    <div>
                      <br />
                      <b>{{data[0].companyPrintName}}</b>
                      <br /> {{data[0].companyAddress}}
                      <br /> {{data[0].companyAddress1}}
                      <br /> {{data[0].companyCity}}
                      <br /> {{data[0].companyState}}
                      <br /> {{data[0].companyPostalCode}}
                      <br /> GST No. : {{data[0].gstin}}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td valign="top" class="bright" style="height:150px">Supplier
                    <div style="padding-left:15px;max-width:400px;display:block;word-wrap:break-word;">
                      <br />
                      <h3 style="margin:0px">{{data[0].supplierPrintName}}</h3>
                      {{data[0].supplierAddress}}
                      <br *ngIf="{{data[0].supplierAddress != ''}}"> {{data[0].supplierAddress1}}
                      <br *ngIf="{{data[0].city != ''}}"> {{data[0].city}}
                      <br *ngIf="{{data[0].supplierAddress1 != ''}}"> {{data[0].postalCode}}
                    </div>
                  </td>
                </tr>
              </table>
            </td>
            <td width="50%" valign="top">
              <table width="100%" border="0" cellspacing="0" cellpadding="0">
                <tr>
                  <td>
                    <table width="100%" border="0" cellspacing="0" cellpadding="5">
                      <tr>
                        <td style="width:150px;" class=" bright bbottom">Date
                          <br />
                          <strong>{{data[0].poDate}}</strong>
                          </td>

                          <td class="bbottom">Mode/Terms of Payment
                            <br />
                            <strong>{{data[0].modeOfPayment}} </strong>
                          </td>
                      </tr>

                      <tr>
                        <td style="height:42px;overflow:auto;" class="bright bbottom">Purchase Order No
                          <br />
                          <strong>{{data[0].poId}}</strong>
                        </td>
                        <td valign="top" class="bbottom">Indent No / Ref No
                          <br />
                          <strong>{{data[0].indentNos}} </strong>
                        </td>
                      </tr>
                      <tr>
                        <td style="height:32px;overflow:auto;" valign="top" class="bright bbottom">Dispatch through
                          <br />
                          <strong>{{data[0].dispatchThru}}</strong>
                        </td>
                        <td valign="top" class="bbottom address">Destination
                          <br />
                          <strong> {{data[0].destinationAddress}}</strong>
                        </td>
                      </tr>
                    </table>
                    </td>
                </tr>
                <tr>
                  <td valign="top">Terms of Delivery
                    <br />
                    <strong>{{data[0].termsOfDelivery}}</strong>
                  </td>
                </tr>
              </table>
              </td>
          </tr>
        </table>
        </td>
    </tr>
    <tr>
      <td>
        <table id="mytable" width="100%" border="1" class="bfull" cellspacing="0" cellpadding="5">
          <tr>
            <td width="5%" align="center" valign="middle">SI No.</td>
            <td width="35%" align="center" valign="middle">Description of Goods</td>
            <td width="10%" align="center" valign="middle">Quantity</td>
            <td width="9%" align="center" valign="middle">Unit Price</td>
            <td width="6%" align="center" valign="middle">Tax %</td>
            <td width="6%" align="center" valign="middle">Disc. %</td>
            <td width="14%" align="center" valign="middle">Amount</td>
          </tr>

          <tr ng-repeat="i in d.JobId">
            <td align="center">{{i.sno}}</td>
            <td>
              <strong>{{i.itemName}}</strong>
              <br />
              <span>{{i.poDetailItemSpec}}</span>
              <br />
              <strong *ngIf="validateRemarks(i.remarks)">Add.Info</strong>
              <span>{{i.remarks}}</span>
            </td>
            <td align="left">
              <strong>{{i.poQty}} {{i.poPrintUom}}</strong>
            </td>
            <td align="right">{{i.poRate.toFixed(2)}}</td>
            <td align="right">{{i.poTaxPercent}}</td>
            <td align="right">{{i.poDiscountPercent}}</td>
            <td align="right" class="">{{getnetamount(i.poQty,i.poRate,i.poDiscountPercent,i.poTaxPercent)}}</td>
          </tr>

          <tr *ngIf="$index+1==count">
            <td colspan="2" style="border:none;">&nbsp;</td>
            <td align="right" colspan="4">Discount Amount</td>
            <td align="right">{{data[0].poDiscount.toFixed(2)}}</td>
          </tr>
          <tr *ngIf="$index+1==count">
            <td colspan="2" style="border:none;">&nbsp;</td>
            <td align="right" colspan="4">Tax Amount</td>
            <td align="right">{{data[0].poTax.toFixed(2)}}</td>
          </tr>
          <tr *ngIf="$index+1==count">
            <td colspan="2" style="border:none;">&nbsp;</td>
            <td align="right" colspan="4">Frieght</td>
            <td align="right">{{data[0].poFrieght.toFixed(2)}}</td>
          </tr>
          <tr *ngIf="$index+1==count">
            <td colspan="2" style="border:none;">&nbsp;</td>
            <td align="right" colspan="4"> Misc charge</td>
            <td align="right">{{data[0].poMisccharge.toFixed(2)}}</td>
          </tr>

          <tr *ngIf="$index+1==count">
            <td colspan="2" style="border:none;">&nbsp;</td>
            <td align="right" colspan="4">Total</td>
            <td align="right">&#8377;
              <strong>
                {{data[0].grandTotal}}
              </strong>
            </td>
          </tr>
        </table>
      </td>
      <tr>
        <td>
          <table *ngIf="{{$index+1==count}}" width="100%" border="0" cellspacing="0" cellpadding="5">
            <tr>
              <td width="42%">Amount Chargeable (in words)</td>
              <td width="58%" align="right">E. &amp; O. E</td>
            </tr>
            <tr>
              <td colspan="2">
                <b>{{changeAmountToWordsWithPaise(data[0].grandTotal)}}</b>
              </td>
            </tr>
            <tr>
              <td colspan="2" style="height:140px;">&nbsp;</td>
            </tr>
            <tr>
              <td colspan="2">Remarks:
                <br/>
                <strong class="remarks">
                  {{data[0].remarks}}</strong>
              </td>
            </tr>
            <tr>
              <td>&nbsp;</td>
              <td align="right" valign="top" class="btop bleft" style="height:50px;">
                <b>for {{data[0].companyPrintName}}</b>
              </td>
            </tr>
            <tr>
              <td>&nbsp;</td>
              <td align="right" class="bleft">Authorised Signatory</td>
            </tr>
          </table>
          <div align="right" ng-hide="{{$index+1==count}}">please turn over</div>
        </td>
      </tr>
  </table> -->