<section class="welcome_screen">
    <div class="container">
        <div class="row">
            <div class="col">
                <h1>Department Indent Approval System</h1>
                <h6>
                    <i>Namaskaram</i> &nbsp;&nbsp; {{userEmail}} !
                </h6>
            </div>
        </div>
        <div class="row">
            <div class="col-md-2"></div>
            <div class="col">
                <img alt="" src="../../../assets/images/welcome_design.png" />
            </div>
            <div class="col-md-2"></div>
        </div>
        <div class="row">
            <div class="col">
                <p class="copyrights"> &copy; Isha foundation</p>
            </div>
        </div>
    </div>
</section>