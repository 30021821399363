import { Component, OnInit, Optional, Inject } from '@angular/core';
import { SnackBarComponent } from '../../snack-bar/snack-bar.component';
import { MatDialogRef, MAT_DIALOG_DATA, MatTableDataSource } from '@angular/material';
import {
  SuperAdminSupport
} from 'src/app/businessModules/superadmin/support/support-model/support.model';

@Component({
  selector: 'app-remarks-display',
  templateUrl: './remarks-history-sadmin.component.html',
  styleUrls: ['./remarks-history-sadmin.component.css']
})
export class RemarksHistorySadminComponent implements OnInit {

  constructor(
    @Optional() public dialogRef: MatDialogRef<RemarksHistorySadminComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: SuperAdminSupport[],
    private _snackBarComponent: SnackBarComponent) { }

  displayedColumns = ['Person', 'Mail','DateTime', 'Process', 'Response', 'Error'];
  dataSource: MatTableDataSource<SuperAdminSupport>;

  ngOnInit() {
    this.dataSource = new MatTableDataSource(this.data);
  }

  /* Close the dialog box */
  public close() {
    this.dialogRef.close(0);
  }

}
