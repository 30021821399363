<mat-toolbar>

  <h3 mat-dialog-title>
        <span id="first">
           Remarks
        </span>

    <span  id="second">
            {{data}}
        </span>

  </h3>
  <span class="spacer"></span>
  <mat-icon aria-label="close" (click)="close()" class="close">close</mat-icon>
</mat-toolbar>

<div class="container">
  <form>
  <div class="row">
    <div class="col-md-12 textCenter">
      <mat-form-field>
        <input [(ngModel)]="remarks" name="remarks" matInput #message   (keyup)="pressMethod($event)"  required placeholder="Remarks">
        <mat-error >{{getErrorMessage()}}</mat-error>
      </mat-form-field>
      <button id="rsubmit" mat-raised-button (click)="submitRemarks(message.value)">submit</button>
    </div>
  </div>
  </form>
</div>