<mat-toolbar class="componentToolbar">
  <h1 mat-dialog-title>Remarks History</h1>
  <span class="spacer"></span>
  <mat-icon aria-label="close" (click)="close()" class="close">close</mat-icon>
</mat-toolbar>
<mat-dialog-content  >
<section>
  <div class="box-shadow">
    <table mat-table [dataSource]="dataSource">

      <ng-container matColumnDef="Person">
        <th mat-header-cell *matHeaderCellDef>Person</th>
        <td mat-cell *matCellDef="let row"> {{row.name}} </td>
      </ng-container>


      <ng-container matColumnDef="Mail">
        <th mat-header-cell *matHeaderCellDef>Mail</th>
        <td mat-cell *matCellDef="let row"> {{row.emailId}} </td>
      </ng-container>

      <ng-container matColumnDef="Process">
        <th mat-header-cell *matHeaderCellDef>Process</th>
        <td mat-cell *matCellDef="let row"> {{row.process}} </td>
      </ng-container>

      <ng-container matColumnDef="Response">
        <th mat-header-cell *matHeaderCellDef>Response</th>
        <td mat-cell *matCellDef="let row"> {{row.response}} </td>
      </ng-container>

      <ng-container matColumnDef="Error">
        <th mat-header-cell *matHeaderCellDef>Error</th>
        <td mat-cell *matCellDef="let row"> {{row.error}} </td>
      </ng-container>

      <ng-container matColumnDef="DateTime">
        <th mat-header-cell *matHeaderCellDef>DateTime</th>
        <td mat-cell *matCellDef="let row"> {{row.modifiedAt * 1000 | date:'yyyy-MM-dd HH:mm:ss'}}</td>
      </ng-container>

     
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky:true;"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;">
      </tr>
    </table>
  </div>
</section>
</mat-dialog-content>
