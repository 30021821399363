import { KeycloakService } from 'keycloak-angular';
import * as configuration from "../assets/configs/url-configs.json";

export function initializer(keycloak: KeycloakService): () => Promise<any> {
  
  let url: string = "";

  if (window.location.href.indexOf(configuration.windowLocation.local) > -1) {
    url = configuration.qa.qaKeycloak;
  } else if (window.location.href.indexOf(configuration.windowLocation.qa) > -1) {
    url = configuration.qa.qaKeycloak;
  } else if (window.location.href.indexOf(configuration.windowLocation.prod) > -1) {
    url = configuration.production.keycloakProd;
  }

  return (): Promise<any> => keycloak.init({
    config: {
      url: url,
      realm: 'Isha',
      clientId: 'DAIS'
    },
    initOptions: {
      onLoad: 'login-required',
      checkLoginIframe: false
    },
    enableBearerInterceptor: false,
    bearerExcludedUrls: ['/assets', '/clients/public']
  });
}
