import {
  Component,
  OnInit,
  Inject,
  forwardRef,
  OnDestroy
} from "@angular/core";
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatSelectChange
} from "@angular/material";
import { HttpErrorResponse } from "@angular/common/http";
//import { ExceptionHandlingService } from "../../../util-services/exception-handling.service";
import { Subscription } from "rxjs";
//import { DnApprovalLatestService } from "../../../services/dn-approval-latest.service";

import {
  NG_VALUE_ACCESSOR,
  FormControl,
  FormGroup,
  FormBuilder,
  Validators
} from "@angular/forms";
//import { MyErrorStateMatcher } from "../../../directives/errorStateMatcher";
import { SnackBarComponent } from '../snack-bar/snack-bar.component';
import { DepartmentDetails } from '../commonService/common.model';
import { CommonDataService } from '../commonService/common-data.service';
import { MaService } from 'src/app/businessModules/approvals/material-acknowledgement/ma-service/ma.service';
import { SwapItem, MailInfoByTypeRequest, MailInfo, SaveSwap } from 'src/app/businessModules/approvals/material-acknowledgement/ma-model/ma.model';

@Component({
  selector: "app-swap",
  templateUrl: "./swap.component.html",
  styleUrls: ["./swap.component.css"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SwapComponent),
      multi: true
    }
  ]
})
export class SwapComponent implements OnInit, OnDestroy {
  listSubscription: Subscription[] = [];
  userRole: string;
  exportDate: string;
  departments: DepartmentDetails[] = [];
  swapFormControl: FormGroup = this.createSwapForm();
  postForm: any = "";
  deptId: number;
  processType: string;
  primaryMailId: string;
  loadingSpinner: boolean = false;
  saveError: string;
  //matcher = new MyErrorStateMatcher();

  fromDate: Date;
  fromValue: number = 0;
  toValue: number = 0;
  swapId: number = 0;
  primaryMailIdOptions: string[] = [];
  activeSwapList: SwapItem[] = [];
  displayedColumns = ["actions", "fromDate", "primaryMailId"];
  showForm = false;
  isValueRangeEnable: boolean = true;
  arrvalueRange: string[] = [];

  valueRanges: FormControl = new FormControl('');
  dept: FormControl = new FormControl('');

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<SwapComponent>,
    public _commonDataService: CommonDataService,
    public _snackbarComponent: SnackBarComponent,
    public _maService: MaService,
    private fb: FormBuilder
  ) {
    this.processType = data.processType
  }
  private minDate: Date = new Date();

  ngOnInit() {
    this.exportDate = SnackBarComponent.dateConvertor(new Date());
    let userEmailId: string = this._commonDataService.userEmail;
    this.userRole = ''

    let sub: Subscription = this._maService
      .getDepartmentBasedUser(userEmailId, this.userRole, this.processType)
      .subscribe(
        result => {
          this.departments = result;
          this.departments.sort((a, b) => {
            if (a.deptName < b.deptName) return -1;
            if (a.deptName < b.deptName) return 1;
            return 0;
          });
        },
        (error: HttpErrorResponse) => {
          //this.exceptionHandlingService.throwException(error);
        }
      );
    this.listSubscription.push(sub);
  }

  createSwapForm(row = null) {
    if (!row) {
      row = {
        swapId: 0,
        fromDate: "",
        primaryMailId: "",
        fromValue: 0,
        toValue: 0,
        valueRange: 0
      };
    }
    return this.fb.group({
      processType: this.processType,
      swapId: row.swapId,
      primaryMailId: [row.primaryMailId, [Validators.required, Validators.email]],
      fromDate: [row.fromDate, Validators.required],
      fromValue: row.fromValue,
      toValue: row.toValue,
      valueRange: row.valueRange = `${row.fromValue}` + '-' + `${row.toValue}`
    });
  }

  /* When the department changes */
  public departmentChange(): void {
    this.deptId = this.dept.value;
    this.getListOfMailInfo();
    console.log(this.swapFormControl);
  }

  fetchActiveSwapList(row?: SaveSwap) {
    if (this.deptId) {
      let swapDataRequest = {
        deptId: this.deptId,
        processType: this.processType,
        // fromValue: this.fromValue > 0 ? row.fromValue : this.fromValue,
        // toValue: this.toValue > 0 ? row.toValue : this.toValue,
        fromValue: row == undefined ? this.fromValue : row.fromValue,
        toValue: row == undefined ? this.toValue : row.toValue,
        userRole: this.userRole
      };
      let sub: Subscription = this._maService
        .getActiveSwapList(swapDataRequest)
        .subscribe(result => {
          this.activeSwapList = result;
        });
      this.listSubscription.push(sub);
    }
  }

  /* updating the date to string */
  updateDateToString(event): void {
    let newDate = new Date(event);
    this.exportDate = SnackBarComponent.dateConvertor(newDate);
  }

  /* Closes the dialog box */
  public onNoClick(): void {
    this.dialogRef.close();
  }

  public fcfetchActiveUser(event: MatSelectChange) {
    this.fromValue = event.value.split('-')[0];
    this.toValue = event.value.split('-')[1];
    this.fetchActiveSwapList();
  }

  public getListOfMailInfo() {
    let mailInfoByType = new MailInfoByTypeRequest()
    mailInfoByType.deptId = this.deptId;
    mailInfoByType.processType = this.processType;
    mailInfoByType.userRole = this.userRole;

    if (this.deptId) {
      let sub: Subscription = this._maService
        .getDeptCCEmails(mailInfoByType)
        .subscribe(
          result => {
            this.swapFormControl.reset();
            let arrPrimaryMailIds = [];

            console.log("This is the result of swap after dpt change: ", result);

            const resultObject: MailInfo = result;
            this.arrvalueRange = [];

            resultObject.mailInfo.map(item => {
              arrPrimaryMailIds.push(item.primaryEmailId);
              if (resultObject.isValueRange === 1) {
                this.arrvalueRange.push(`${item.fromValue}` + '-' + `${item.toValue}`);
                this.isValueRangeEnable = false;
              } else {
                this.isValueRangeEnable = true;
              }
            });
            this.primaryMailIdOptions = arrPrimaryMailIds.concat(resultObject.swapMailList);
            this.primaryMailIdOptions = Array.from(new Set(this.primaryMailIdOptions));
          },
          (error: HttpErrorResponse) => {
            //this.exceptionHandlingService.throwException(error);
          }
        );
      this.listSubscription.push(sub);
      this.fetchActiveSwapList();
    }
  }

  public editSwap(row) {
    this.swapFormControl = this.createSwapForm(row);
    this.showForm = true;
  }

  /* when the submit button is clicked */
  public onSubmit(post) {
    if (this.swapFormControl.valid) {
      this.loadingSpinner = true;
      this.postForm = post;
      post.fromDate = isoToApiDateParser(post.fromDate);

      if (this.arrvalueRange.length > 0) {
        post.fromValue = this.valueRanges.value.split('-')[0];
        post.toValue = this.valueRanges.value.split('-')[1];
      }

      let saveSwap: SaveSwap = new SaveSwap();
      saveSwap.deptId = this.deptId;
      saveSwap.fromValue = post.fromValue ? post.fromValue : 0;
      saveSwap.toValue = post.toValue ? post.toValue : 0;
      saveSwap.fromDate = post.fromDate;
      saveSwap.primaryMailId = post.primaryMailId;
      saveSwap.processType = post.processType ? post.processType : this.processType;
      saveSwap.swapId = post.swapId;

      this._maService.saveSwap([saveSwap]).subscribe(
        result => {
          console.log({ result });
          this.loadingSpinner = false;
          if (result.returnStatus === 1) {
            this.fetchActiveSwapList(saveSwap);
            this.showForm = false;
          } else {
            if (result.detailedMessage) {
              this._snackbarComponent.openSnackFailure(result.detailedMessage);
            }
          }
        },
        (error: HttpErrorResponse) => {
          this.loadingSpinner = false;
          console.log({ error })
          //this.exceptionHandlingService.throwException(error);
        }
      );
    } else {
      this.showForm = false;
    }
  }
  ngOnDestroy(): void {
    this.listSubscription.map(subscribe => {
      subscribe.unsubscribe();
    });
  }
}

const isoToApiDateParser = dateString => {
  const date = new Date(dateString);
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const year = date.getFullYear();
  console.log(
    { dateString, year, month, day, date },
    `${year}-${month}-${day}`
  );
  return `${year}-${month}-${day}`;
};
