import { Component, ElementRef, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { SnackBarComponent , Convert } from 'src/app/shared/snack-bar/snack-bar.component';
import {  Optional, Inject } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef, MatSelectChange} from '@angular/material';

import { DNPrintHeader,DNPrintDetail } from 'src/app/businessModules/reports/indent-status-report/indent-status-report-model/indent-status-report.model';
import { Subscription } from 'rxjs';

import { CommonService } from 'src/app/shared/commonService/common.service';
@Component({
  selector: 'app-dn-print',
  templateUrl: './dn-print.component.html',
  styleUrls: ['./dn-print.component.css']
})

export class DnPrintComponent implements OnInit {

  //arrPoPrint: PrintPurchaseOrder[] = [];
  data: DNPrintHeader[] = [];
  listSubscription : Subscription[] = [];
  userName: string = "";
  arrDnDetail: DNPrintDetail[] = [];
  deliveryDate: string = "";
  @ViewChild('content') po : ElementRef;
   constructor(private route: ActivatedRoute,
    public dialogRef: MatDialogRef<DNPrintHeader>,
    private router: Router,
    @Optional() @Inject(MAT_DIALOG_DATA) public ddata: any,

    private snackBarComponent: SnackBarComponent,


    private _commonService: CommonService) {

     }

  ngOnInit() {
    this.getPoPrint();
    this.userName = 'xxx';

  }

private dataConvertorPrint(date: Date): string {
        let outputDate: string = '';
        let mm: string = Convert.toString(date.getMonth() + 1);
        let dd: string = Convert.toString(date.getDate());
        let yy: string = Convert.toString(date.getFullYear());

        outputDate = `${dd}-${mm}-${yy}`;
        return outputDate;
    }
  /* retrieving the data for PO print */
  getPoPrint(): void {

    let dnId =this.ddata;
           console.log(dnId);

           let grandTotal = 0;

           let subscribeDnPrint: Subscription = this._commonService.getDNAttachment('DN',dnId)
               .subscribe(response => {
                   console.log('response from DN Print', response);
                   this.data = response;
                   //this.snackBarComponent.openSnackFailure('Please Wait');
                   this.data.map(result => {

                       this.deliveryDate = this.dataConvertorPrint(new Date(result.deliveryDate));
                       this.arrDnDetail = result.deldetail;
                       result.deldetail.map(dnResult => {
                           //dnResult.indentNo = dnResult.indentNo.split('/')[0];
                           dnResult.totalRate = parseFloat(dnResult.totalRate.toFixed(2));
                           grandTotal += dnResult.totalRate;
                       });
                       result.grandTotal = parseFloat(grandTotal.toFixed(2));
                      // result.rupeersInWords = SnackBarComponent.ChangeAmountToWordsWithPaise(result.grandTotal);
                   });

                   setTimeout(() => {
                       let printContents, popupWin;
                       printContents = document.getElementById('content').innerHTML;//this.dn.nativeElement.innerHTML;
                       popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
                       popupWin.document.open();
                       popupWin.document.write(`
                           <html>
                               <head>
                               <title>Print tab</title>
                               <style>
                               .remarks {
                                   word-wrap: break-word;
                                   display: block;
                                   max-width: 13.50cm;
                                   padding: 5px;
                               }

                               body {
                                   page-break-after: auto;
                                   background: rgb(204,204,204);
                                   font-size: 14px;
                                   font-family: Arial, Helvetica, sans-serif;
                               }

                               /*page[size="A4"]*/
                               div.a4 {
                                   background: white;
                                   min-width: 5.5in;
                                   width: 6in;
                                   max-width: 6.5in;
                                   /*min-height: 8.5in;*/
                                   min-height: 6in;
                                   height: auto;
                                   display: block;
                                   position: relative;
                                   /*margin-bottom: 0.5cm;*/
                                   box-shadow: 0 0 0.5cm rgba(0,0,0,0.5);
                               }

                               .bleft {
                                   border-left: solid 1px #000000;
                               }

                               .bright {
                                   border-right: solid 1px #000000;
                               }

                               .btop {
                                   border-top: solid 1px #000000;
                               }

                               .bbottom {
                                   border-bottom: solid 1px #000000;
                               }

                               .maintd {
                                   width: 100%;
                                   border: solid 1px #000000;
                               }

                               .bfull {
                                   /*border: solid 1px #666666;*/
                                   border: solid 1px #000000;
                                   border-collapse: collapse;
                               }

                               .fontsizetwentyfour {
                               }

                               .fontsizetwentysix {
                               }

                               .fontsizetwentyeight {
                               }

                               #tb2 {
                                   counter-reset: serial-number;  /* Set the serial number counter to 0 */
                               }

                               #tb2 .tabletwo:before {
                                   counter-increment: serial-number;  /* Increment the serial number counter */
                                   content: counter(serial-number);  /* Display the counter */
                               }

                               @media print {

                                   html, body {
                                       font-family: 'Times New Roman';
                                       font-size: 20px;
                                       margin-bottom: 0px;
                                       margin-top: 0px;
                                   }

                                   .fontsizetwentyfour {
                                       font-size: 24px;
                                   }

                                   .fontsizetwentysix {
                                       font-size: 26px;
                                   }

                                   .fontsizetwentyeight {
                                       font-size: 28px;
                                   }

                                   @page {
                                       width: 5.5in;
                                       height: 8.5in;
                                       display: block;
                                       /*margin: 0px 10px 0px 0px;
                                       margin-bottom: 20px;
                                       margin-top: 20px;*/
                                       margin-top: 12px;
                                       margin-left: 0px;
                                       margin-right: 0px;
                                       size: 5.5in 8.5in;

                                       @top-right-corner {
                                           content: "Hamlet";
                                       }
                                   }

                                   #content {
                                       display: table;
                                   }

                                   #pageFooter {
                                       display: table-footer-group;
                                   }

                                   #pageFooter:after {
                                       counter-increment: page;
                                       content: counter(page);
                                   }
                               }
                               </style>
                               </head>
                               <body onload="window.print();window.close()">${printContents}</body>
                           </html>`
                       );
                       popupWin.document.close();
                   }, 1000);
               });
           this.listSubscription.push(subscribeDnPrint);
  }

  ngOnDestroy(): void {
    this.listSubscription.map(subscribe => {
      subscribe.unsubscribe();
    });

  }
}
