import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { WelcomeHomeComponent } from './shared/welcome-home/welcome-home.component';

const routes: Routes = [
  {
    path: '', 
    component: WelcomeHomeComponent
  },
  {
    path: 'indent',
    loadChildren: './businessModules/indent-draft/indent-draft.module#IndentDraftModule'
  },
  {
    path: 'masters',
    loadChildren: './businessModules/masters/masters.module#MastersModule'
  },
  {
    path: 'approvals',
    loadChildren: './businessModules/approvals/approvals.module#ApprovalsModule'
  },
  {
    path: 'mappings',
    loadChildren: './businessModules/mapping/mapping.module#MappingModule'
  },
  {
    path: 'department-indent',
    loadChildren: './businessModules/department-indent/department-indent.module#DepartmentIndentModule'
  },
  {
    path: 'auth',
    loadChildren: './businessModules/authorisation/authorisation.module#AuthorisationModule'
  },
  {
    path: 'reports',
    loadChildren: './businessModules/reports/reports.module#ReportsModule'
  },
     {
       path: 'superadmin',
       loadChildren: './businessModules/superadmin/support.module#SupportModule'
     }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
