<mat-toolbar>
  <h3 mat-dialog-title>
    Show Old / Changed Value
  </h3>
  <span class="spacer"></span>
  <mat-icon aria-label="close" (click)="close()" class="close">close</mat-icon>
</mat-toolbar>

<section class="height">
  <div class="container">
    <div class="row">
      <div class="col-md-6">
        <h3>OLD VALUE</h3>
        <div innerHTML="{{data.oldValue}}"></div>
      </div>
      <div class="col-md-6">
        <h3>NEW VALUE</h3>
        <div innerHTML="{{data.newValue}}"></div>
      </div>
    </div>
  </div>
</section>