import { Injectable } from '@angular/core';
import { 
    HttpClient, 
    HttpParams, 
    HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CommonDataService } from '../commonService/common-data.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class RootPurchaseApiService {

    Root_Purchase_URL = environment.purchase;

    constructor(private _http: HttpClient,
        private _commonDataService: CommonDataService) { 
        this._http.get('../../assets/configs/url-configs.json').subscribe(response => {
            let res: any = response;
            
            if (window.location.href.indexOf(res.windowLocation.local) > -1) {
                this.Root_Purchase_URL = environment.purchase;
              } else if (window.location.href.indexOf(res.windowLocation.qa) > -1) {
                this.Root_Purchase_URL = environment.purchase;
              } else if (window.location.href.indexOf(res.windowLocation.prod) > -1) {
                this.Root_Purchase_URL = environment.purchase;
              }
        });
     }

    public GetData<TOutput>(apiName: string, httpParams: HttpParams): Observable<TOutput> {
        const httpOptions = {
            headers: new HttpHeaders({
                'X-Application-ID': '4',
                'From': this._commonDataService.userEmail
            }),
            params: httpParams
        };
        return this._http.get<TOutput>(`${this.Root_Purchase_URL}${apiName}`, httpOptions);
    }

    public PostData<TResponse, TInput>(apiName: string, input: TInput): Observable<TResponse> {
        const httpOptions = {
            headers: new HttpHeaders({
                'X-Application-ID': '4',
                'From': this._commonDataService.userEmail
            })
        };
        return this._http.post<TResponse>(`${this.Root_Purchase_URL}${apiName}`, input, httpOptions);
    }

    public PutData<TResponse, TInput>(apiName: string, input: TInput): Observable<TResponse> {
        const httpOptions = {
            headers: new HttpHeaders({
                'X-Application-ID': '4',
                'From': this._commonDataService.userEmail
            })
        };
        return this._http.put<TResponse>(`${this.Root_Purchase_URL}${apiName}`, input, httpOptions);
    }
}
