import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
const CSV_TYPE = 'text/csv';
const CSV_EXTENSTION = '.csv';

@Injectable()
export class ExportToExcelService {

  constructor() { }

  /*
  TO use in order to to a .xlsx file.
  */
  public exportAsExcelFile(json: any[], excelFileName: string): void {
    if (json == null || json.length == 0) {
      return;
    }    
    
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'SheetA': worksheet }, SheetNames: ['SheetA'] };
    
    //{ bookType:'xlsx', bookSST:false, type:'binary' }
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }


  /*
  TO use in order to to a .csv file.
  */

  public exportAsCsvFile(JSONData: any[], headers: string[], excelFileName: string): void {
    let CSV = '';
    // Add the headers
    let row = "";

    //console.log("Passed in data is :", JSONData);

    if (headers.length == 0) {
      return;
    }

    for (let j = 0; j < headers.length; j++) {
      row += headers[j] + ",";
    }
    row = row.slice(0, -1);
    CSV += row + '\r\n';
    
    /*for(var index in JSONData[0]){
      row += index + ",";
    }
    row =row.slice(0,-1);
    CSV += row +'\r\n';
    */

    for (var i = 0; i < JSONData.length; i++) {
      row = "";
      //2nd loop will extract each column and convert it in string comma-seprated
      for (var index in JSONData[i]) {

        /*
        replace all double quotes to 'inches'
        */
        if(typeof(JSONData[i][index]) === 'string'){
          let repString: string = '"';
          let newString: string = ' inch';
          let mainStr: string= JSONData[i][index];

          while(mainStr.indexOf(repString) != -1){
            mainStr= JSONData[i][index];
            JSONData[i][index] = mainStr.replace(repString, newString);
          }
        }
        row += '"' + JSONData[i][index] + '",';
        //console.log("Row data is :", row);
      }
      row.slice(0, row.length - 1);
      //add a line break after each row
      CSV += row + '\r\n';
      //console.log("CSV is :", CSV);
    }
    if (CSV != '') {
      this.saveAsCSVFile(CSV, excelFileName);
    }
  }



  private saveAsCSVFile(buffer: any, fileName: string): void {
    console.log("Buffer is :", buffer)
    const data: Blob = new Blob([buffer], {
      type: "text/csv"
    });
    console.log("Blob value is :", data);

    FileSaver.saveAs(data, fileName + '_export_' + (new Date()).toISOString() + CSV_EXTENSTION);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    console.log("Buffer is :", buffer)
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    console.log("Blob value is :", data);

    FileSaver.saveAs(data, fileName + '_export_' + (new Date()).toISOString() + EXCEL_EXTENSION);
  }

}
