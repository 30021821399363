export class IndentFlowParams {
    deptId: number;
    itemId: number;
    indentNo: string;
    fromDate: string;
    toDate: string;
}

export class IndentFlowDetails {
    sno: string;
    id: number;
    indentHeaderId: number;
    deptId: number;
    entityName: string;
    indentNumber: string;
    indentDate: number;
    requesterName: string;
    itemId: number;
    itemName: string;
    specId: number;
    specDesc: string;
    itemUom: string;
    itemQty: number;
    approximatePrice: number;
    totalPrice: number;
    detailCreateDate: number;
    budgetId: number;
    ledgerId: number;
    ledgerHead: string;
    purposeId: number;
    purpose: string;
    budgetCode: string;
    remarks: string;
    modifiedAt: number;
    modifiedBy: string;
    itemStatus: string;
    itemWfStatus: string;
    itemStatusDetail: string;
    approvalPerson: string;
    deApprovalStatus: string;
    status: string;
    indentDateFormat: string;

    indentCurrentStatus: string = 'click here';
    itemCorrection: string = 'click here';
    purchaseStatus: string = 'click here';
}
export class IndentFlowDetailsReport {
    sno: string;
    id: number;
    indentHeaderId: number;
    deptId: number;
    entityName: string;
    indentNumber: string;
    indentDate: number;
    requesterName: string;
    itemId: number;
    itemName: string;
    specId: number;
    specDesc: string;
    itemUom: string;
    itemQty: number;
    approximatePrice: number;
    totalPrice: number;
    detailCreateDate: number;
    budgetId: number;
    ledgerId: number;
    ledgerHead: string;
    purposeId: number;
    purpose: string;
    budgetCode: string;
    remarks: string;
    modifiedAt: number;
    modifiedBy: string;
    itemStatus: string;
    itemWfStatus: string;
    itemStatusDetail: string;
    approvalPerson: string;
    deApprovalStatus: string;
    status: string;
    indentDateFormat: string;

}

export class IndentCurrentStatusDetails {
    role: string;
    approvalStatus: string;
    approvedBy: string;
    approvalPerson: string;
    approvedAt: string;
    remarks: string;
}

export class ItemCorrectionDetails {
    oldValue: string;
    newValue: string;
    createdBy: string;
    createdAt: string;
    modifiedBy: string;
    modifiedAt: string;
    status: string;
}

export class PurchaseStatusDetails {
    itemStatus: string;
    modifiedAt: string;
    modifiedBy: string;
    remarks: string;
}


export class IndentRemarksDetails {
    indentDetailId: number;
    remarks: string;
    modifiedBy: string;
    action: string;
    process: string;
    clientId: string;
    verifyRemarks: string;
}

