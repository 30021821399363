import { Component, OnInit, Output, Input } from '@angular/core';
import { NavigationLink, navigation } from './../../config/navigation-config';
import { Observable, Subscription } from 'rxjs';
import { MatSidenav } from '@angular/material';
import { EventEmitter } from 'events';
import { CommonService } from 'src/app/shared/commonService/common.service';
import { CommonDataService } from 'src/app/shared/commonService/common-data.service';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css']
})
export class NavigationComponent implements OnInit {

  @Input() inputSideNav: MatSidenav;
  @Output() public toggle = new EventEmitter();

  navigationLinks$: Observable<NavigationLink[]>;
  arrNavigation: NavigationLink[] = [];
  arrSubscription: Subscription[] = [];
  // navigation.map( navlinks => {
  //   this.arrNavigation.push(navlinks);
  //   console.log('array Navigation : ', this.arrNavigation);
  // });

  constructor(private _commonService: CommonService,
    private _commonDataService: CommonDataService) { }

  ngOnInit() {
    this._commonDataService.isUserDetailsLoadedFromKeycloakObs.subscribe(isUserDetailsLoaded => {
      if (isUserDetailsLoaded) {
        let emailId = this._commonDataService.userEmail;
        let navigationSubscription = this._commonService.getUserNavigationList('DAIS', emailId).subscribe(navlinks => {
          this.arrNavigation = navlinks.userRoles;
          console.log('[NAVIGATION] - User details are loaded from keycloak');
          this.arrNavigation.map(parentRoute => {
            parentRoute.children.map(childRoute => {
              if(childRoute.routerLink === 'department-indent/requestor'){
                this._commonDataService.notificationOnlyForRequester(true);
              }
            });
          });
        });
        this.arrSubscription.push(navigationSubscription);
      } else {
        console.log('[NAVIGATION] - User details are not loaded from keycloak')
      }
    }, error => {
      console.log('[NAVIGATION] - Please contact the support team');
    });
  }

  justChecking() {
    this.inputSideNav.close();
  }

  fireToggle(e) {
    console.log('firing the toggle');
    this.toggle.emit(e);
  }

  ngOnDestroy() {
    this.arrSubscription.map(each => {
      each.unsubscribe();
    });
  }

}
