<mat-toolbar class="dim-toolbar">
  <button mat-icon-button (click)="fireToggle($event)">
    <i class="fas fa-align-left"></i>
  </button>
  <h1 class="dim-app-name">Department Indent Approval System</h1>
  <div class="spacer"></div>

  <!-- Notification-->
  <button mat-icon-button (click)="notificationToggle($event)">
    <mat-icon matBadge="{{itemNotificationCount}}" matBadgeColor="warn" [matBadgeHidden]="isNotifyCountZero" aria-hidden="false" aria-label="Example home icon">notifications</mat-icon>
  </button>
  
  <!-- To display the username from keycloak in matMenu -->
  <mat-icon aria-hidden="false" aria-label="Example home icon" [matMenuTriggerFor]="menu">more_vert</mat-icon>
  <mat-menu #menu="matMenu">
    <div class="profile_picture">
      <i class="fas fa-user-circle"></i>
    </div>
    <button mat-menu-item>{{userName}}</button>
    <button mat-menu-item (click)="logout()">Logout</button>
  </mat-menu>
</mat-toolbar>