import { Component, OnInit, Optional, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import {FormBuilder, FormGroup, Validators,FormControl} from '@angular/forms';
@Component({
  selector: 'app-remarks',
  templateUrl: './remarks.component.html',
  styleUrls: ['./remarks.component.css']
})
export class RemarksComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<RemarksComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) { }
remarks;

  ngOnInit() {
  console.info(this.data);
  if(this.data!=null && this.data!=undefined){
  (<HTMLInputElement> document.getElementById("first")).style.display = 'none';
  }else{
  (<HTMLInputElement> document.getElementById("second")).style.display = 'none';
  }
  }

  /**
  * Error Message to display below the field 
  */
  public getErrorMessage(): string {
    return 'Required field';
  }

  public getErrorMessage1(): string {

      return 'Value cannot be empty!';
    }

  /**
   * submit the remarks 
   */
  submitRemarks(value: string){

  let checkVal=value;

  if(checkVal.trim()==''){
(<HTMLInputElement> document.getElementById("rsubmit")).disabled = true;

  }else{
    this.dialogRef.close(1);
    }
  }
pressMethod(event: any) {

if(event!=undefined){
    if(event.target.value!=undefined){
        if(event.target.value.trim()===''){
            (<HTMLInputElement> document.getElementById("rsubmit")).disabled = true;
            this.getErrorMessage();
        }else{
            (<HTMLInputElement> document.getElementById("rsubmit")).disabled = false;
        }
    }
}
}
  close(){
    this.dialogRef.close(0);
  }


}
