import { Component, OnInit, Optional, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.css']
})
export class ConfirmationDialogComponent implements OnInit {

  constructor(public dialogRef : MatDialogRef<ConfirmationDialogComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) { }

  draftName:string;
  isSaveDraft: boolean;
  isItForConfirmation: boolean;
  confirmationMessage: string;

  ngOnInit() {
    this.isItForConfirmation = this.data.isForConfirmation ? true : false;
  }

  close(){
    this.dialogRef.close(1);
  }

  confirm(message){
    this.dialogRef.close(message);
  }
}
